<template>
  <div class="steps">
    <div class="steps__content">
      <div class="steps__items">
        <div :class="step_classes('amount')">
          <div class="steps__item-indicator" />
          <div class="steps__item-text">
            Offer
          </div>
        </div>
        <div :class="step_classes('entity')">
          <div class="steps__item-indicator" />
          <div class="steps__item-text">
            Investor Information
          </div>
        </div>
        <div :class="step_classes('subdocs')">
          <div class="steps__item-indicator" />
          <div class="steps__item-text">
            Subscription Documents
          </div>
        </div>
        <div :class="step_classes('accred')">
          <div class="steps__item-indicator" />
          <div class="steps__item-text">
            Investor Verification
          </div>
        </div>
        <div :class="step_classes('funding')">
          <div class="steps__item-indicator" />
          <div class="steps__item-text">
            Funding Investment
          </div>
        </div>
      </div>
      <div class="steps__progress">
        <div class="steps__progress-indicator" :class="[vdata.ui_state.current_tab, (vdata.offer.investment_stage || '').replace(/_/g,'-')]" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubNav',
  props: {
    vdata: {
      type:     Object,
      required: true
    }
  },
  computed: {
    progress_width() {
      const widths = {
        'amount': 0,
        'entity': 26,
        'subdocs': 51,
        'accred': 76
      }
      if(this.vdata.ui_state.current_tab != 'funding'){
        return(widths[this.vdata.ui_state.current_tab] + '%');
      }else{
        if(["ready_to_fund", "awaiting_funds", "funds_received"].includes(this.vdata.offer.investment_stage)){
          return "100%";
        }else{
          return "88%";
        }
      }
    }
  },
  methods: {
    step_classes(tab) {
      let classes = { steps__item: true };
      if(tab == 'funding' && this.vdata.ui_state.current_tab === 'funding'){ //special case for last step
        switch( this.vdata.offer.investment_stage ){
          case "ready_to_fund":
            classes['steps__item--active'] = true;
          break;
          case "awaiting_funds":
          case "funds_received":
            classes['steps__item--completed'] = true;
          break;
        }
        return classes;
      }
      if(tab == this.vdata.ui_state.current_tab){
        classes['steps__item--active'] = true;
      }
      const steps = {
        'amount': 0,
        'entity': 1,
        'subdocs': 2,
        'accred': 3,
        'funding': 4
      }
      if(steps[this.vdata.ui_state.current_tab] > steps[tab]){
        classes['steps__item--completed'] = true;
      }
      return classes;
    }
  }
};
</script>
