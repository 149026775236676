<template>
  <div class="row">
    <div :class="cap_trans_classes">
      <div class="table__wrapper table__wrapper--small">
        <table class="table">
          <thead class="table__thead">
            <tr class="table__tr">
              <th class="table__th">
                Date
              </th>
              <th class="table__th">
                Amount
              </th>
              <th class="table__th">
                Entity
              </th>
              <th class="table__th">
                Transaction Type
              </th>
            </tr>
          </thead>
          <TBody :data="data" />
        </table>
      </div>
    </div>
    <div :class="data_viz_classes">
      <InvestmentDataViz :vdata="data_viz_source" @open="expand" @close="collapse" />
    </div>
  </div>
</template>

<script>
import { default as TBody } from './capital-transactions-t-body.vue';
import { default as InvestmentDataViz } from './investment-data-viz.vue';

export default {
  name: 'InvestmentCapitalTransactions',
  components: {
    TBody,
    InvestmentDataViz
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      state: 'closed'
    }
  },
  computed: {
    data_viz_source() {
      return {
        data: this.data
      };
    },
    cap_trans_classes(){
      return {
        "small-12": true,
        "medium-8": this.state == "closed",
        "hide": this.state == "open",
        "column": true
      }
    },
    data_viz_classes(){
      return {
        "small-12": true,
        "medium-4": this.state == "closed",
        "column": true,
        "text-center": true
      }
    }
  },
  methods: {
    collapse() {
      this.state = 'closed';
    },
    expand() {
      this.state = 'open';
    }
  }
};
</script>
