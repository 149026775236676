<template>
  <div class="investment-summary__donut">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      class="donut"
    >
      <circle
        class="donut-hole"
        cx="25"
        cy="25"
        r="15.91549430918954"
      />
      <circle
        class="donut-ring"
        cx="25"
        cy="25"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="10"
      />
      <circle
        class="donut-segment"
        :class="'donut-percent-'+ fill"
        cx="25"
        cy="25"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="10"
        :stroke-dasharray="dasharray"
        stroke-dashoffset="25"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'GraphDonut',
  props: {
    fill: {
      type: Number,
      required: true
    }
  },
  computed: {
    dasharray(){
      const dasharray = `${this.fill} ${100-this.fill}`;
      return(dasharray);
    },
  }
};
</script>
