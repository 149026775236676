/*
 *
 * @file investment-summary
 *
 */


import { createApp, reactive } from "vue";
import { configureVueAppDefaults } from 'shared/vue-configured';
const App = require('./investment-summary.vue').default;

class InvestmentSummary {

  constructor(app) {
    this.init = this.init.bind(this);
    this.get_base_data = this.get_base_data.bind(this);
    this.select_entity = this.select_entity.bind(this);
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
    $(document)
      .on('change', '[data-investment-entity]', this.select_entity)
  }

  init() {
    if (!$("[data-vue-target=investment-summary]").length) {
      return;
    }

    const vdata = reactive(this.get_base_data());
    this.vdata = vdata;
    this.vm = createApp(App, {vdata});
    configureVueAppDefaults(this.vm);
    this.vm.mount("[data-vue-target=investment-summary]");

    // this.vm = new Vue({
    //   el: '[data-vue-target=investment-summary]',
    //   components: { App },
    //   data: this.set_base_data,
    //   template: '<App v-bind="{ investments, active_investment }"/>'
    // });
    window._vm = this.vdata;
  }

  select_entity(e) {
    if(e.target.value == ""){
      this.vdata.active_investment = false;
      $("[data-investment]").slideDown()
    }else{
      this.vdata.active_investment = parseInt(e.target.value);
      let active_investment = this.vdata.active_investment;
      $("[data-investment]").each( (i,o)=> {
        if($(o).data('entityId') === active_investment){
          $(o).slideDown();
        }else{
          $(o).slideUp();
        }
      })
    }
  }

  get_base_data() {
    const investments = $("[data-investment]").get().map( (object) => {
      return {
        entity_id: parseInt($(object).data('entityId')),
        status: $(object).data('status'),
        invested: parseFloat($(object).find('[data-invested]').data('invested')),
        distributed: parseFloat($(object).find('[data-distributed]').data('distributed')),
        capitalBalance: parseFloat($(object).find('[data-capital-balance]').data('capitalBalance'))
      };
    }),
    base_data = {
      investments: investments,
      active_investment: false
    };
    return base_data;
  }


}


export default InvestmentSummary;
