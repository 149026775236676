<template>
  <div class="data-viz data-viz--distros" :class="mode_class()" data-cy="data-viz">
    <div class="data-viz__actions">
      <div v-if="mode != 'closed'">
        <button
          type="button"
          class="data-viz__actions-button"
          :class="{ 'data-viz__actions-button--active': mode == 'quarter' }"
          @click="set_mode('quarter')"
        >
          Quarterly
        </button>
        <button
          type="button"
          class="data-viz__actions-button"
          :class="{ 'data-viz__actions-button--active': mode == 'year' }"
          @click="set_mode('year')"
        >
          Annually
        </button>
        <button
          type="button"
          class="data-viz__actions-button data-viz__actions-button--close"
          data-cy="data-viz__close-button"
          @click="set_mode('closed')"
        />
      </div>
      <div v-if="mode == 'closed'">
        <button
          type="button"
          class="data-viz__actions-button button"
          data-cy="data-viz__open-button"
          @click="set_mode('quarter')"
        >
          EXPAND GRAPH
        </button>
      </div>
    </div>
    <div class="data-viz__notice">
      Click and drag to move. Mouse wheel to zoom in/out.
    </div>
    <div class="data-viz__notice data-viz__notice--no-data">
      No data at this time
    </div>
    <div ref="chart_container" class="data-viz__chart" data-cy="data-viz__chart" />
  </div>
</template>

<script>
import * as c3 from 'c3'
import * as d3 from 'd3'
import * as datefns from 'date-fns';

window.d3 = d3;
export default {
  name: 'InvestmentDataViz',
  props: {
    vdata: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mode: 'closed',
      chart: false,
      chart_data: false
    }
  },
  watch: {
    vdata(){ this.update_chart() },
    mode(){ this.update_chart() }
  },
  mounted() {
    this.chart = c3.generate(this.c3_config());
    window.chart = this.chart; // debugging
  },
  methods: {
    dup(vdata){ // don't manipulate the reactive Vue data, avoid side effects
      return(JSON.parse(JSON.stringify(vdata)));
    },
    c3_config() {
      return({
        bindto: '.data-viz__chart',
        size: this.c3_size(),
        axis: {
          y: {
            tick: {
              format: this.c3_ytick // d3.format("$,") // ADD
            }
          },
          x : {
              type: this.mode == 'closed' ? undefined : 'timeseries',
              tick: {
                  // multiline: true,
                  culling: false,
                  format: this.c3_xtick
                //format: '%Y' // format string is also available for timeseries data
              }
          }
        },
        data: {
            x: 'x',
            columns: this.c3_bar_data(this.vdata.data, this.mode),
            type: 'bar',
            colors: {
                 distros: [], // hack, prevents color assignments in the style attributes
            },
            labels: {
              format: {
                distros: this.c3_dist_label
              }
            },
        },
        bar: {
          width: {
           ratio: 0.6 // this makes bar width 50% of length between ticks
           }
        },
        zoom: {
            enabled: $(window).width() > 1023
        },
        tooltip: {
          show: false,
          // format: {
          //     title: function (d) { return 'Data ' + d; },
          //     value: function (value, ratio, id) {
          //         var format = id === 'data1' ? d3.format(',') : d3.format('$');
          //         return format(value);
          //     }
          // }
        }
      });
    },
    c3_bar_data(incoming, mode) {
      let raw = this.dup(incoming);
      let data;
      if(mode == 'closed'){
        data = [['x'], ['distros']];
        this.labels = {
          1: ["Initial", "Investment"],
          2: ["Total", "To Date"]
        }
        data[0].push(1);
        data[0].push(2);
        data[1].push( this.get_initial_investment(raw) );
        data[1].push( this.sum_of_distros(raw) );
      }
      // For quarter and year modes, we calculate a minimum of 8 ticks;
      // Tick labels that are in the future will not be shown, but they will
      // be present in the graph for space reasons
      if(mode == 'quarter'){
        // note the reverse() as the data comes in in reverse chronological order
        const parsed_arr = raw.map( (v) => Object.assign( { date_p: datefns.parseISO(v.date) }, v ) ).reverse();
        const last_date = parsed_arr[parsed_arr.length - 1].date_p
        const span = Math.max(8, datefns.differenceInCalendarQuarters( last_date, parsed_arr[0].date_p ));
        let this_quarter_start = datefns.startOfQuarter(parsed_arr[0].date_p);
        const init_date = datefns.getTime(datefns.subQuarters(this_quarter_start, 1)); //x pos of the initial investment
        const separator_date = datefns.getTime(datefns.subDays(this_quarter_start, 45)); //x pos of the separator bar
        this.chart.xgrids.remove();
        this.chart.xgrids.add({ value: separator_date });
        let labels = {}
        labels[init_date] = ["Initial", "Investment"];
        labels[separator_date] = [];
        let xvals = [init_date, separator_date];
        let amounts = [this.get_initial_investment(parsed_arr), 0];
        let year, quarter, next_quarter_start, timestamp;
        for(let i=0; i<=span; i++){
          timestamp = datefns.getTime(this_quarter_start);
          xvals.push(timestamp);
          next_quarter_start = datefns.addQuarters(this_quarter_start, 1);
          year = datefns.getYear(this_quarter_start);
          quarter = datefns.getQuarter(this_quarter_start);
          if(datefns.isFuture(this_quarter_start)){
            labels[timestamp] = "";
          }else{
            labels[timestamp] = [`Q${quarter}`,`${year}`];
          }

          const reducer = (a, v) => {
            if(datefns.isSameQuarter(this_quarter_start, v.date_p) && v.type == 'Distribution'){
              a += v.amount;
            }
            return a;
          }
          amounts.push( parsed_arr.reduce(reducer, 0) );
          this_quarter_start = next_quarter_start;
        }
        this.labels = labels;
        data = [
          ['x'].concat(xvals),
          ['distros'].concat(amounts)
        ]
      }
      if(mode == 'year'){
        // note the reverse() as the data comes in in reverse chronological order
        const parsed_arr = raw.map( (v) => Object.assign( { date_p: datefns.parseISO(v.date) }, v ) ).reverse();
        const last_date = parsed_arr[parsed_arr.length - 1].date_p
        const span = Math.max(8, datefns.differenceInYears( last_date, parsed_arr[0].date_p ));
        let this_year_start = datefns.startOfYear(parsed_arr[0].date_p);
        const init_date = datefns.getTime(datefns.subYears(this_year_start, 1)); //x pos of the initial investment
        const separator_date = datefns.getTime(datefns.subQuarters(this_year_start, 2)); //x pos of the separator bar
        this.chart.xgrids.remove();
        setTimeout( ()=> this.chart.xgrids.add({ value: separator_date }), 400 )
        let labels = {}
        labels[init_date] = ["Initial", "Investment"];
        labels[separator_date] = [];
        let xvals = [init_date, separator_date];
        let amounts = [this.get_initial_investment(raw), 0];
        let year, next_year_start, timestamp;
        for(let i=0; i<=span; i++){
          timestamp = datefns.getTime(this_year_start);
          xvals.push(timestamp);
          next_year_start = datefns.addYears(this_year_start, 1);
          year = datefns.getYear(this_year_start);
          if(datefns.isFuture(this_year_start)){
            labels[timestamp] = "";
          }else{
            labels[timestamp] = [`${year}`];
          }

          const reducer = (a, v) => {
            if(datefns.isSameYear(this_year_start, v.date_p) && v.type == 'Distribution'){
              a += v.amount;
            }
            return a;
          }
          amounts.push( parsed_arr.reduce(reducer, 0) );
          this_year_start = next_year_start;
        }
        this.labels = labels;
        data = [
          ['x'].concat(xvals),
          ['distros'].concat(amounts)
        ]
      }
      return data;
    },
    c3_xtick(x) {
      return this.labels[x];
    },
    c3_ytick(x) {
      if(this.mode == 'closed'){
        return null;
      }else{
        return d3.format("$~s")(x);
      }
    },
    c3_size() {
      return {
        height: this.mode === 'closed' ? 200 : 300,
        width: this.mode === 'closed' ? 200 : 800
      }
    },
    c3_dist_label(v) {
      if(v == 0){
        return("");
      }
      if(v > 999){
        return d3.format("$,.3~s")(v);
      }
      // Revert to this if d3.format doesnt' work out
      // if(v > 999){
      //   let string = (v / 1000).toString()
      //   let [ks, ds] = string.split(".")
      //   let formatted = v;
      //   if(ds == null){
      //     ds = "";
      //   }
      //   let decimals = Math.max(3 - ks.toString().length, 0);
      //   ds = ds.substring(0, decimals).replace(/0$/g,'');
      //   formatted = ks;
      //   if(ds != ""){
      //     formatted += `.${ds}`;
      //   }
      //   formatted += "K";
      // }
      return d3.format("$,.2~f")(v);
    },
    get_initial_investment(raw) {
      const reducer = (a, v) => {
        if(v.type == "Initial Investment" || v.type == "Capital Call"){
          a += v.amount;
        }
        return a;
      }
      return( raw.reduce(reducer, 0) );
    },
    mode_class() {
      let k = {};
      k[`data-viz--${this.mode}`] = true
      const has_data = this.vdata.data.reduce( (a, v) => {
        if(v.type == 'Distribution'){
            if(v.amount > 0){
              return true;
            }
        }
        return a;
      }, false );
      k["data-viz--no-data"] = !has_data;
      return k;
    },
    set_mode(mode) {
      if(mode == 'closed'){
        this.$emit('close');
      }else{
        this.$emit('open');
      }
      this.mode = mode;
    },
    sum_of_distros(raw){
      const reducer = (a, v) => {
        if(v.type == "Distribution"){
          a += v.amount;
        }
        return a;
      }
      return( raw.reduce(reducer, 0) );
    },
    update_chart(){
      if(this.mode == 'closed'){
          this.chart = c3.generate(this.c3_config());
          return;
      }
      this.chart.resize(this.c3_size());
      this.chart_data = this.c3_bar_data(this.vdata.data , this.mode);
      this.chart.load({columns: this.chart_data});
    }
  }
};
</script>
