<template>
  <tbody class="table__tbody">
    <tr
      v-for="row in data"
      :key="row.id"
      class="table__tr"
      :class="{ 'table__tr--new': !row.seen_by_user }"
      data-cy="capital-transactions__row"
    >
      <td width="25%" class="table__td table__td--nowrap">
        {{ ac_medium_date(row.date) }}
      </td>
      <td width="25%" class="table__td">
        {{ currency(row.amount) }}
      </td>
      <td width="25%" class="table__td">
        {{ row.entity_name }}
      </td>
      <td width="25%" class="table__td">
        {{ row.type }}
      </td>
    </tr>
  </tbody>
</template>

<script>
import { ac_medium_date, currency } from 'shared/view-filters';

export default {
  name: 'TBody',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    ac_medium_date,
    currency
  }
};

</script>

<style scoped>
  svg {
    height: 20px;
    width: 20px;
  }
</style>
