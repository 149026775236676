/*
 *
 * @file analytics
 * Handles sending events to the GTM container per Extractable's specs
 *
 */

class Analytics {

  constructor(app) {
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
  }

  catch_event = ()=> {

    setTimeout(()=> {

      this.push_event({
        'event':    'VPV',
        'pagename': document.title
      });

    }, 1000);

    if (window.data_layer_event) {
      this.push_event(window.data_layer_event);
    }
  }

  /**
   * Push an event to dataLayer
   * @param {object} event - a dataLayer event object
   */
  push_event = (event)=> {
    if (event.UID == null) {
      event.UID = $('body').data('uid');
    }
    if (typeof dataLayer !== 'undefined' && typeof event === 'object') {
      window.dataLayer.push(event);
    }

    if (typeof event === 'object') {
      console.info('Analytics.push_event:', event);
    } else {
      console.error('Analytics.push_event expects an object');
    }

    if (window.data_layer_event) {
      this.reset_event;
    }
  }

  reset_event = ()=> {
    window.data_layer_event = null;
  }

  init = ()=> {
    this.catch_event();
  }
}

export default Analytics;
