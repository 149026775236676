<template>
  <div>
    <div class="row collapse">
      <div class="column small-12">
        <div class="panel__section small-ml-0 small-mr-0 small-mt-35">
          <div class="row">
            <div class="column small-12 medium-6">
              <h2 class="panel__subheading panel__subheading--big" data-cy="investment-detail__heading">
                Investment Details
              </h2>
            </div>
            <div class="column small-12 medium-6 medium-text-right">
              <span class="text text-small text-dove-gray text-primary-roman" data-cy="investment-detail__entity-label">
                Entity:
              </span>
              <select
                v-if="investments.length > 2"
                v-model="selected_investment"
                class="select select--clean small-mb-7 large-mb-12"
                data-cy="offers__entity-filter"
              >
                <option v-for="(investment) in investments" :key="investment.entity.id" :value="investment">
                  {{ investment.entity.legal_entity_name }}
                </option>
              </select>
              <select
                v-else
                class="select select--clean select--single-entity small-mb-7 large-mb-12"
              >
                <option>{{ investments[1].entity.legal_entity_name }}</option>
              </select>
            </div>
          </div>
          <InvestmentData :data="selected_investment.investment_data" />
        </div>
        <div class="panel__section small-ml-0 small-mr-0">
          <h3 class="panel__subheading panel__subheading--big">
            Capital Transactions
          </h3>
          <div class="box">
            <InvestmentCapitalTransactions :data="selected_investment.transactions" />
          </div>
        </div>
        <div class="panel__section small-ml-0 small-mr-0">
          <h3 class="panel__subheading panel__subheading--big">
            Documents
          </h3>
          <div class="box">
            <DocumentList
              title="Documents"
              :endpoint="documents_endpoint(selected_investment)"
              :show_investment_column="false"
              :heights="{ small: 200, large: 400}"
            />
          </div>
        </div>
        <div class="panel__section small-ml-0 small-mr-0">
          <h3 class="panel__subheading panel__subheading--big">
            Recent Activity
          </h3>
          <div class="box">
            <ActivityLog
              endpoint="/investor/portfolio/activity"
              :deal="vdata.deal.id"
              :entity="selected_investment.entity.id"
              show="5"
              :allow_load_on_scroll="false"
              :view_all_link="true"
              title="Recent Activity"
              :heights="{ small: 200, large: 400}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as ActivityLog } from './activity-log.vue';
import { default as DocumentList } from './document-list.vue';
import { default as InvestmentData } from './components/investment-data.vue';
import { default as InvestmentCapitalTransactions } from './components/investment-capital-transactions.vue';

export default
  ({
    name: "InvestmentDetail",
    components: {
      ActivityLog,
      DocumentList,
      InvestmentData,
      InvestmentCapitalTransactions
    },
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        ui_state: {
          fetching: false
        },
        investments: this.vdata.investments,
        selected_investment: this.vdata.investments.length === 2 ? this.vdata.investments[1] : this.vdata.investments[0]
      };
    },
    methods: {
      documents_endpoint(investment){
        // If multiple entities made offers on the deal, the first item in the investments
        // array will be for the 'total' and will not have an offer associated.
        const offer_id = investment.offer ? investment.offer.id : this.vdata.investments[1].offer.id;
        return `/investor/portfolio/offers/${offer_id}/documents`;
      },
      fetch() {
        if (this.ui_state.fetching) {
          this.refetch = true;
          return;
        }
        this.ui_state.fetching = true;
        return new Promise((resolve, reject)=> {
          // prepare the payload by converting the needed attributes to a plain JS object
          const payload = JSON.parse(JSON.stringify(this.params));
          $.ajax({
              url: this.endpoint,
              type: "GET",
              data: payload,
              dataType: 'json',
              success: this.receive.bind(this, resolve, reject),
              error: this.fetch_failure.bind(this, reject)
          });
        });
      },

      // The payload that comes back from the server has structure:
      // invesments: []
      // entities: []
      // offer_documents: []
      // new_items: {}
      receive(resolve, reject, response){
        this.ui_state.fetching = false;
        if (this.refetch) {
          this.refetch = false;
          this.fetch();
        }
        if (!this.investments) { this.investments = response.investments; }
        if (!this.entities) { this.entities = response.entities; }
        this.offer_documents = response.offer_documents;
        this.new_items = response.new_items;
        resolve(response);
      },

      fetch_failure(reject, jqXHR){
        this.ui_state.fetching = false;
        alert('There was a problem fetching data');
        console.log(jqXHR);
        reject();
      },

      set_document_type(type){
        this.ui_state.current_tab = type;
        this.params.document_type = type;
      }
    }
  });
</script>
