<template>
  <tbody class="table__tbody">
    <tr
      v-for="row in result"
      :key="row.document_type + row.id"
      class="table__tr"
      :class="{ 'table__tr--new': !row.seen_by_user }"
      data-cy="documents__row"
    >
      <td class="table__td table__td--nowrap">
        {{ format(new Date(row.timestamp * 1000), 'MMM dd, yyyy') }}
      </td>
      <td class="table__td">
        <a :href="row.uri" target="_blank">{{ row.name }}</a>
      </td>
      <td
        v-for="column in columns"
        :key="column.field"
        :width="column.width"
        class="table__td"
      >
        <template v-if="column.field == 'legal_entity_name'">
          <a v-if="row.entity_link" :href="row.entity_link" data-cy="documents__link">
            {{ row[column.field] }}
          </a>
          <span v-else>
            {{ row[column.field] }}
          </span>
        </template>
        <a v-if="column.field == 'investment'" :href="row.investment_link">
          {{ row[column.field] }}
        </a>
        <span v-if="['legal_entity_name', 'investment'].indexOf(column.field) < 0">
          {{ row[column.field] }}
        </span>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'TBody',
  props: {
    result: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  },
  methods: {
    format: require('date-fns').format
  }
};

</script>

<style scoped>
  svg {
    height: 20px;
    width: 20px;
  }
</style>
