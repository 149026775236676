/*
 *
 * @file submit-offer
 * Governs state and transitions for the offer flow at /investor/offer/new and
 * /investor/offer/ID
 *
 */

import { createApp, reactive } from "vue";
import { configureVueAppDefaults, EventBus } from 'shared/vue-configured';
const App = require('./submit-offer.vue').default;
import Turbolinks from 'turbolinks'
import boundFunctions from 'shared/bound-functions'
// const currency = require('currency.js');

const OfferCopy = {
  awaiting_funds: "<h2 class=\"callout__heading\">Thank you for indicating that you have remitted your funds.</h2> Once we have received your funds, you will be notified.",
  awaiting_other_signatures: "<h2 class=\"callout__heading\">Thank you for submitting your verification letter.</h2> Your subscription document is awaiting signatures from additional parties. You will receive an email when you can proceed to the next step.",
  awaiting_approval: "<h2 class=\"callout__heading\">Thank you for submitting your verification letter.</h2> You will receive an email when you can proceed to the next step.",
  docs_received: "<h2 class=\"callout__heading\">Thank you for submitting your subscription documents and verification letter.</h2> Our team will review your documents, and you will receive an email when you have been approved and can move to the next step.",
  awaiting_receipt: "<h2 class=\"callout__heading\">Thank you for sending your subscription documents.</h2> You will receive an email when ArborCrowd receives them.",
  entity_locked: "<h2 class=\"callout__heading\">Your offer and entity information have been pre-approved and can no longer be modified.</h2> If you need to change your offer, please contact Investor Relations at <a href='mailto:investorrelations@arborcrowd.com'>investorrelations@arborcrowd.com</a>.",
  funds_received: "<h2 class=\"callout__heading\">Once all of the funds from all of the investors have been received, we will close the transaction.</h2> You’ll receive an email and your countersigned documents will be available to you.",
  not_preapproved: "<h2 class=\"callout__heading\">Thank you for submitting an offer.</h2> Our team will review your offer, which usually takes one business day. You will receive an email when you can proceed to the next step. ",
  verification_in_progress: "<h2 class=\"callout__heading\">Your verification is in progress.</h2> You will receive an email when you can proceed to the next step.<br> If you would like to change your verification method, please email <a href='mailto:investorrelations@arborcrowd.com'>InvestorRelations@arborcrowd.com</a>.",
  verification_requires_attn: `<h2 class="callout__heading">Your verification is in progress.</h2> Your verification request with VerifyInvestor is in progress and requires additional action or information. To continue with your verification request, click <a href='URL'>here</a>.`
}


class SubmitOffer {

  constructor(app) {
    boundFunctions([
      "init",
      "accred_control_url",
      "default_ui_state",
      "entity_signatories",
      "goto_step",
      "max_signatory_slots",
      "next_step",
      "push_and_fetch_data",
      "push_accred_data",
      "push_subdoc_data",
      "push_success",
      "reconcile_requested_slots",
      "receive_message",
      "select_accred_method",
      "select_subdoc_method",
      "set_document_title",
      "start_docusign_process",
      "update_entity"
    ], this);
    // this.validate_amount = this.validate_amount.bind(this);
    // his.validate_entity = this.validate_entity.bind(this);
    // this.validate_subdocs = this.validate_subdocs.bind(this);
    // this.validate_accred = this.validate_accred.bind(this);
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
    window.addEventListener("message", this.receive_message, false);
  }

  init() {
    // Check to see if we are on the submit page
    if (!$("[data-vue-target=submit-offer]").length) {
      this.vm = false;
      return;
    }
    console.log("SubmitOffer:init");
    const base_data = window.submit_offer_data;
    base_data.ui_state = this.default_ui_state(base_data); // do some config here in future
    // base_data.ui_defs.accred_upload_url = @accred_upload_url()
    // base_data.ui_defs.accred_download_url = "/investor/deals/#{submit_offer_data.deal.id}/accreditation_template"
    // base_data.ui_defs.subdoc_download_url = @subdoc_download_url()
    base_data.controller = this;
    this.set_document_title(
      base_data.deal.name,
      base_data.ui_state.current_tab
    );
    const vdata = reactive(base_data);
    this.vm = createApp(App, { vdata });
    configureVueAppDefaults(this.vm);
    this.vm.mount("[data-vue-target=submit-offer]");

    // this.vm = new Vue({
    //   el: '[data-vue-target=submit-offer]',
    //   components: { App },
    //   data() {
    //     return {vdata: base_data};
    //   },
    //   template: '<App v-bind:vdata="vdata"/>'
    // });
    window._vm = this.vm;
  }


  accred_control_url() {
    const data_source = this.vm ? this.vm.vdata : window.submit_offer_data;
    return `/investor/entities/${data_source.entity.id}/accreditations/?offer_id=${data_source.offer.id}`;
  }

  calculate_tab_states(data) {
    let state = {
      'amount': { disabled: this.offer_is_pre_approved(data.offer), message: OfferCopy.entity_locked },
      'entity': { disabled: this.offer_is_pre_approved(data.offer), message: OfferCopy.entity_locked }
    }
    if(!this.offer_is_pre_approved(data.offer)){
      state['subdocs'] = { disabled: true, message: OfferCopy.not_preapproved }
      state['accred'] = { disabled: true, message: OfferCopy.not_preapproved }
      state['funding'] = { disabled: true, message: OfferCopy.not_preapproved }
    }else{
      if(this.vm){
        state['subdocs'] = { disabled: !!this.vm.vdata.ui_state.docusign_iframe_url, message: false }
      }else{
        state['subdocs'] = { disabled: false, message: false }
      }


      if(data.accreditation && data.accreditation.status === "in_review" ){
        if(
          data.accreditation.accreditation_type == 'verify_investor' &&
          [
            "waiting_for_investor_acceptance",
            "accepted_by_investor",
            "waiting_for_information_from_investor"
          ].includes(data.accreditation.verifyinvestor_status)
        ){
          state['accred'] = { disabled: true, message: OfferCopy.verification_requires_attn.replace('URL', data.verify_investor_login_url) }
        }else{
          state['accred'] = { disabled: true, message: OfferCopy.verification_in_progress }
        }
      }else{
        state['accred'] = { disabled: false, message: false }
      }

      if(data.offer.investment_stage == "ready_to_fund"){
          state['funding'] = { disabled: false, message: false }
      }else if(data.offer.investment_stage == "awaiting_funds"){
        state['funding'] = { disabled: true, message: OfferCopy.awaiting_funds }
      }else if(data.offer.investment_stage == "funds_received"){
        state['funding'] = { disabled: true, message: OfferCopy.funds_received }
      }else{
        if(data.offer.subscription_document.status == "received"){
          state['funding'] = { disabled: true, message: OfferCopy.docs_received }
        }else if(data.offer.subscription_document.status == "in_transit"){
          state['funding'] = { disabled: true, message: OfferCopy.awaiting_receipt }
        }else if(data.offer.subscription_document.status == "other_sigs"){
          state['funding'] = { disabled: true, message: OfferCopy.awaiting_other_signatures }
        }else{
          state['funding'] = { disabled: true, message: OfferCopy.awaiting_approval }
        }
      }
    }


    return state;
  }

  default_ui_state(submit_offer_data) {
    let step = window.location.search.match(/s=([a-z]+)/);
    step = step ? step[1] : 'amount';
    return({
      current_tab: step,
      fetching: false,
      disclaimer_1: !!submit_offer_data.entity.id,
      disclaimer_2: !!submit_offer_data.entity.id,
      docusign_iframe_url: false,
      notice: '',
      max_signatory_slots: this.max_signatory_slots(submit_offer_data),
      mailing_address_different: false,
      requested_signatory_slots: this.entity_signatories().length || 1,
      show_notice_modal: false,
      subdoc_control_url: this.subdoc_control_url(submit_offer_data),
      tabs: [
        'amount',
        'entity',
        'subdocs',
        'accred',
        'funding'
      ],
      tab_states: this.calculate_tab_states(submit_offer_data),
      verify_investor_url: submit_offer_data.verify_investor_url
    });
  }

  default_member(t) {
    return {
      first_name: "",
      last_name: "",
      user: "",
      access_type: t
    };
  }

  entity_signatories() {
    if ( this.vm && this.vm.vdata.entity ) {
      return (this.vm.vdata.entity.members.filter((mem) => ['owner','signatory'].includes(mem.access_type)));
    } else {
      return [];
    }
  }

  finish_flow() {
    window.location.href = "/investor/offers";
  }

  goto_step(step) {
    this.vm.vdata.ui_state.current_tab = step;
    const new_loc = window.location.pathname + '?s=' + step;
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(new_loc, Turbolinks.uuid())
    this.set_document_title(
      this.vm.vdata.deal.name,
      step
    );
    this.app.components.Analytics.push_event({
      'event':    'VPV',
      'pagename': document.title
    });
    $("html, body").animate({ scrollTop: 0}, 500);
  }

  max_signatory_slots(data) {
    if (data == null) { data = this.vm.vdata; }
    switch ((data.entity != null ? data.entity.entity_type : undefined)) {
      case 'individual':
        return 1;
      case 'joint': case 'sdira':
        return 2;
      case 'partnership': case 'trust': case 'corporation': case 'other':
        return 10;
      default:
        return 0;
    }
  }

  next_step() {
    let i = this.vm.vdata.ui_state.tabs.indexOf( this.vm.vdata.ui_state.current_tab ) + 1;
    const next_step = this.vm.vdata.ui_state.tabs[i];
    // const error = this[`validate_${this.vm.vdata.ui_state.current_tab}`]();
    // if( error ){ // non-null means an error happened
    //   EventBus.$emit('submit-offer-notice', error);
    //   this.app.components.Analytics.push_event({
    //     'event':      'error',
    //     'error_type': error
    //   });
    // }else{
    this.push_and_fetch_data()
      .then(this.goto_step.bind(this, next_step))
    // }
  }

  // Offer is in pre-approved or later stage
  offer_is_pre_approved(offer){
    return( [
      "pre_approved",
      "documentation_review",
      "ready_to_fund",
      "awaiting_funds",
      "funds_received",
      "countersigned",
      "rejected",
      "withdrawn"
    ].includes(offer.investment_stage) );
  }


  push_and_fetch_data(opts = {}) {
    return new Promise((resolve, reject) => {
      // prepare the payload by converting the needed attributes to a plain JS object
      // critically, strip out the controller property which is a circular reference
      let payload = {
        deal: this.vm.vdata.deal,
        offer: this.vm.vdata.offer,
        entity: this.vm.vdata.entity,
        accreditation: this.vm.vdata.accreditation,
        user: this.vm.vdata.user,
        refresh_docusign: !!opts.refresh_docusign
      };
      payload = JSON.stringify(payload);
      let url = this.vm.vdata.ui_defs.base_ajax_url;
      if (this.vm.vdata.offer.id) {
        url += `/${this.vm.vdata.offer.id}`;
      }
      $.ajax({
        url,
        type: this.vm.vdata.offer.id ? "PATCH" : "POST",
        data: payload,
        dataType: 'json',
        contentType: 'application/json',
        success: this.push_success.bind(this, resolve, reject),
        error: this.push_failure.bind(this, reject)
      });
    });
  }


  /*
   *
   * This push hits an accreditation-specific endpoint; the payload structure returned is the same
   *
   */
  push_accred_data(payload) {
    return new Promise((resolve, reject) => {
      payload.entity_id = this.vm.vdata.entity.id;
      if (payload.status == null) { payload.status = "in_progress"; }
      payload = JSON.parse(JSON.stringify(payload));
      const url = this.accred_control_url();
      $.ajax({
        url,
        type: "POST",
        data: payload,
        dataType: 'json',
        success: payload => {
          if(payload.errors.length > 0){
            EventBus.$emit('submit-offer-notice', payload.errors.join(", "));
          }
          this.vm.vdata.accreditation = payload.accreditation;
          return resolve();
        },
        error: this.push_failure.bind(this, reject)
      });
    });
  }

  /*
   *
   * This push hits a subdoc-specific endpoint; the payload structure returned is the same
   *
   */
  push_subdoc_data(payload) {
    return new Promise((resolve, reject) => {
      this.vm.vdata.ui_state.fetching = true;
      payload.offer_id = this.vm.vdata.offer.id;
      payload = JSON.parse(JSON.stringify(payload)); // in case Vue data objects are present
      $.ajax({
        url: this.vm.vdata.ui_state.subdoc_control_url,
        type: "PATCH",
        data: payload,
        dataType: 'json',
        success: this.push_success.bind(this, resolve, reject),
        error: this.push_failure.bind(this, reject)
      });
    });
  }

  // The payload that comes back from the server has structure:
  //  data:
  //    deal: [fields...]
  //    offer:
  //       subscription_document [always present]
  //       [other fields...]
  //    entity: [fields...]
  //    accreditation: [fields..., optional]
  //    user: [fields...]
  // errors: []
  // next: optional URL to load (used when creating resources)
  push_success(resolve, reject, response) {
    this.vm.vdata.ui_state.fetching = false;
    if (response.errors.length > 0) { // problem!
      reject(response.errors.join(" "));
      return;
    }
    if (response.next) { // this was a create so we update the URL in case of page reloads
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(`${response.next}?s=amount`, Turbolinks.uuid());
    }
    this.vm.vdata.offer = response.data.offer;
    this.vm.vdata.accreditation = response.data.accreditation;
    if (response.data.entity) { this.vm.vdata.entity = response.data.entity; }
    this.vm.vdata.user = response.data.user;
    this.update_ui_state(response);
    resolve(response);
  }


  push_failure(reject, jqXHR) {
    console.log(jqXHR);
    reject('There was an unexpected error');
  }


  /*
   *
   * Adjust the membershiplist basaed on the number of requested signatories and members
   *
   */
  reconcile_requested_slots() {
    const sigs = this.entity_signatories();
    if (this.vm.vdata.ui_state.requested_signatory_slots > sigs.length) {
      const needed = this.vm.vdata.ui_state.requested_signatory_slots - sigs.length;
      for (let i = 1; i <= needed; i++) { this.vm.vdata.entity.members.push(this.default_member('signatory')); }
    }
    if (this.vm.vdata.ui_state.requested_signatory_slots < sigs.length) {
      const new_sigs = sigs.splice(0, this.vm.vdata.ui_state.requested_signatory_slots);
      this.vm.vdata.entity.members = new_sigs;
    }
  }


  receive_message(event) {
    if (event.origin === window.location.origin && this.vm) {
      const data_type = event && event.data && event.data.type;
      let ds_event;
      switch (data_type) {
        case "docusign_complete":
          this.vm.vdata.ui_state.current_tab = "subdocs"
          ds_event = event.data.ds_event;
          if(ds_event == "ttl_expired"){ // the docusign embed URL is only valid for a few seconds. If it's expired (because of slowserver response we need to regenerate it.)
            this.start_docusign_process();
          }else if( ds_event == "session_timeout" ){
            alert('Your docusign session timed out. Click OK to restart.');
            this.start_docusign_process();
          }else{
            this.vm.vdata.ui_state.docusign_iframe_url = false;
            this.push_and_fetch_data({refresh_docusign: true})
            .then(() => {
              if( ["other_sigs", "received"].includes(this.vm.vdata.offer.subscription_document.status) ){
                this.goto_step('accred');
              }
            });
          }
      }
    }
  }


  // # The user has clicked a radio button on the accred tab
  select_accred_method(method) {
    this.push_accred_data({ accreditation_type: method });
  }


  // # The user has clicked a radio button on the subdoc tab
  select_subdoc_method(method) {
    return this.push_subdoc_data({ submission_method: method });
  }


  async start_docusign_process() {
    const max_attempts = 5;
    let response = false;
    for(let i=1;i <= max_attempts && !response; i++){
      console.log("Docusign attempt "+i)
      try {
        response = await this.push_subdoc_data({ submission_method: "docusign" });
      } catch {
        response = false;
      }
    }
    if(response){
      this.vm.vdata.ui_state.docusign_iframe_url = response.url;
      this.vm.vdata.ui_state.current_tab = false;
    }else{
      EventBus.$emit('submit-offer-notice', "There was an error starting the DocuSign process. Please contact Investor Relations at InvestorRelations@ArborCrowd.com for assistance.");
      this.push_subdoc_data({ submission_method: "unknown" });
    }

  }

  set_document_title(deal_name, current_tab) {
    document.title = `Submit Offer: ${deal_name} | ${current_tab.charAt(0).toUpperCase() + current_tab.slice(1)} Tab`;
  }

  subdoc_control_url(data) {
    return `/investor/deals/${data.deal.id}/offers/${data.offer.id}/subdocs`;
  }

  /*
   *
   * Sets the entity - which may be an existing entity in which case it may not be
   * modifiable. The argument's target value is either the ID of an existing entity
   * or the string "new_[entity_type]"
   *
   * Sets maximum membership numbers fo the pulldowns, verifies
   * and modifies, if necessary, the membership list.
   *
   * The entity.members array is a single property.
   * Signatories have a role value of either 'owner' or 'signatory'
   */
  update_entity(e) {
    this.vm.vdata.ui_state.mailing_address_different = false;
    const m = e.target.value.match(/^new_([a-z_]*)/);
    if (m) { // this is a new entity
      const new_entity = {};
      for (let k in this.vm.vdata.entity) { new_entity[k] = null; }
      new_entity.entity_type = m[1];
      new_entity.members = [];
      this.vm.vdata.entity = new_entity;
      this.vm.vdata.entity.members[0] = {
        first_name: this.vm.vdata.user.first_name,
        last_name: this.vm.vdata.user.last_name,
        user: this.vm.vdata.user.email,
        access_type: "owner"
      };
      switch (this.vm.vdata.entity.entity_type) {
        case 'individual':
          this.vm.vdata.ui_state.requested_signatory_slots = 1;
          if (this.vm.vdata.entity.members.length > 1) {
            this.vm.vdata.entity.members.splice(1);
          }
          break;
        case 'joint': case 'sdira':
          this.vm.vdata.ui_state.requested_signatory_slots = 2;
          if (!this.vm.vdata.entity.members[1]) {
            this.vm.vdata.entity.members[1] = this.default_member('signatory');
          }
          if (this.vm.vdata.entity.members.length > 2) {
            this.vm.vdata.entity.members.splice(2);
          }
          break;
      }
      this.reconcile_requested_slots();
    } else {
      let entity;
      const id = parseInt(e.target.value);
      for (entity of this.vm.vdata.user_entities) {
        if (entity.id === id) {
          this.vm.vdata.entity = entity;
        }
      }
      this.vm.vdata.ui_state.mailing_address_different = (this.vm.vdata.entity.address !== this.vm.vdata.user.address) && (this.vm.vdata.entity.address != "") && (this.vm.vdata.entity.address != null);
      this.vm.vdata.ui_state.requested_signatory_slots = this.vm.vdata.entity.members.length;
      $.getJSON(`/investor/entities/${this.vm.vdata.entity.id}/last_offer`, data => {
        if (data) {
          this.vm.vdata.offer.preferred_distribution_method = data.preferred_distribution_method;
          this.vm.vdata.offer.financial_institution = data.financial_institution;
          this.vm.vdata.offer.account_name = data.account_name;
          this.vm.vdata.offer.account_number = data.account_number;
          this.vm.vdata.offer.routing_ach_number = data.routing_ach_number;
          this.vm.vdata.offer.financial_institution_address = data.financial_institution_address;
          this.vm.vdata.offer.financial_institution_city = data.financial_institution_city;
          return this.vm.vdata.offer.financial_institution_state = data.financial_institution_state;
        }
      });
    }
  }

  // runs after data comes back from the server, takes care of enabling and
  // disabling tabs and adding explanatory messaging
  update_ui_state(response) {
    this.vm.vdata.ui_state.verify_investor_url = response.data.verify_investor_url;
    this.vm.vdata.ui_state.tab_states = this.calculate_tab_states(this.vm.vdata);
    this.vm.vdata.ui_state.subdoc_control_url = this.subdoc_control_url(this.vm.vdata);
  }
}

export { SubmitOffer, OfferCopy };
export default SubmitOffer;
