
/*
 *
 * @file registration - new user signup
 *
 */
import Cookies from 'js-cookie';

class Registration {

  constructor(app) {
    this.init = this.init.bind(this);
    this.app = app;

    this.app.register_turbolinks_load_handler(this.init);



    $(document).on(
      'forminvalid.zf.abide', '#new_user', this.scroll_to_invalid
    )
    .on(
      'focus', '#new_user .input-password', this.show_reqs
    )
    .on(
      'blur', '#new_user .input-password', this.hide_reqs
    )
    .on(
      'change', '#user_how_did_you_hear', this.how_did_you_hear
    )
    .on(
      'change', '#user_email, #user_password', this.populate_google_analytics_fields
    );
  }

  init() {
    if( $(".body__content--sessions-new, .body__content--registrations-new").length == 0 ){
      return;
    }
    setTimeout(this.populate_google_analytics_fields, 1000);
  }

  populate_google_analytics_fields(){
    let ga_field = $('#user_google_analytics_client_id')
    if(ga_field.length && window.ga){
      if(typeof(window.ga.getAll) == 'function' && window.ga.getAll().length){
        ga_field.val( window.ga.getAll()[0].get('clientId') )
      }
    }
    const itf = Cookies.get('lastTouchTrafficSource');
    if(itf){
      let utmcsr = false, utmcmd = false, utmccn = false;
      itf.split("|").map((piece) => {
        let div = piece.split("=");
        let field = div[0];
        let value;
        if(typeof(div[1]) != 'undefined'){
          value = div[1].replace(/\(|\)/g, '');
        }else{
          value = '';
        }

        if(field === "utmcsr"){
          utmcsr = value;
        }
        if(field === "utmcmd"){
          utmcmd = value;
        }
        if(field === "utmccn"){
          utmccn = value;
        }
      })
      if(utmcsr){
        if(utmcmd){
          utmcsr = utmcsr + " / " + utmcmd;
        }
        $('#user_google_analytics_online_source_medium').val(utmcsr);
      }
      if(utmccn){
        $('#user_google_analytics_campaign').val(utmccn);
      }
    }
    const landing = Cookies.get('landing');
    if(landing){
      $('#user_google_analytics_online_landing_page').val(landing);
    }
  }

  scroll_to_invalid() {
    $('html, body').animate( { scrollTop: $('.is-invalid-label, .form-error.is-visible').first().offset().top } )
  }

  show_reqs(){
    $('#password-reqs').slideDown();
  }

  hide_reqs(){
    setTimeout( ()=> {
      if(!$('.input-password:focus').length){
        $('#password-reqs').slideUp();
      }
    }
    , 250);
  }

  how_did_you_hear(e) {
    const target = $(e.currentTarget);
    const selected = target.val();
    const container = $('[data-how-did-you-hear]');

    container.addClass('hide');

    target.find('option').each((index, opt) => {
      if(opt.value != ""){
        if(opt.selected){
          opt.innerText = opt.value.replace(/\(.*\)/, '');
        }else{
          opt.innerText = opt.value;
        }
      }
    })

    if (selected === "Friend or colleague" || selected === "Article or publication" || selected === "Other") {
      container.removeClass('hide');
      $("#user_referred_by").val("")
    }

  }


}

export default Registration;
