<template>
  <div
    v-if="vdata.ui_state.current_tab == 'funding'"
    class="submit-offer__tab submit-offer__tab--funding"
    :class="[tab_class, {'submit-offer__tab--error': tab_error}]"
    data-cy="submit-offer__tab"
  >
    <div class="panel__section">
      <StepMessage
        v-if="!show_deal_card"
        :message="vdata.ui_state.tab_states.funding.message"
      />
      <div
        v-if="show_deal_card"
        class="row align-justify panel__section--force-visible"
      >
        <div class="column small-12 medium-6 large-7">
          <StepMessage :message="vdata.ui_state.tab_states.funding.message" />
        </div>
        <div class="column small-12 medium-6 large-5">
          <div v-html="deal_card_html" />
        </div>
      </div>

      <div class="submit-offer__subtabs tabs hide-on-disabled">
        <h3 class="submit-offer__subheading small-mb-15">
          Select your funding method:
        </h3>

        <p class="small-mb-5">
          Offer Amount: <strong>{{ currency(vdata.offer.amount_submitted) }}</strong>
        </p>

        <p class="text-smaller small-mb-30">
          100% of your committed amount of {{ currency(vdata.offer.amount_submitted) }} is required
        </p>

        <div v-match-heights="{el: ['.submit-offer__subheading']}" class="row align-justify">
          <div class="column tabs__title" :class="{ 'tabs__title--active': ui_state.visible_method == 'wire' }">
            <div class="tabs__title-content first" data-cy="submit-offer__tab-wire">
              <span class="submit-offer__subheading small-mb-14 display-block">Pay by <br class="hide-for-medium">Wire Transfer</span>

              <button
                type="button"
                class="button button--primary button--uppercase button--large"
                :class="{ 'button--disabled': ui_state.visible_method == 'wire' }"
                data-cy="submit-offer__button"
                @click="toggle_tab('wire')"
              >
                Send Wire
              </button>
            </div>
          </div>
          <div class="column small-1 align-middle text-center small-pl-0 small-pr-0">
            <span class="submit-offer__subheading display-block small-mt-30">OR</span>
          </div>
          <div class="column tabs__title" :class="{ 'tabs__title--active': ui_state.visible_method == 'check' }">
            <div class="tabs__title-content" data-cy="submit-offer__tab-check">
              <span class="submit-offer__subheading small-mb-14 display-block">Pay by <br class="hide-for-medium">Check</span>
              <button
                type="button"
                class="button button--primary button--uppercase button--large"
                :class="{ 'button--disabled': ui_state.visible_method == 'check' }"
                data-cy="submit-offer__button"
                @click="toggle_tab('check')"
              >
                Mail Check
              </button>
            </div>
          </div>
        </div>

        <div v-if="ui_state.visible_method == 'wire'" class="tabs__tab">
          <div class="row collapse">
            <div class="column">
              <p>Pay by Wire Transfer:</p>
              <table class="table table--clear table--width-auto">
                <tbody class="table__tbody">
                  <tr class="table__tr">
                    <td class="table__td" valign="top" align="right">
                      To:
                    </td>
                    <td class="table__td" valign="top">
                      <strong>
                        Capital One
                      </strong><br>
                      299 Park Avenue<br>
                      New York, NY 10171
                    </td>
                  </tr>
                  <tr class="table__tr">
                    <td class="table__td" valign="top" align="right">
                      ABA #:
                    </td>
                    <td class="table__td" valign="top">
                      <strong>
                        021407912
                      </strong>
                    </td>
                  </tr>
                  <tr class="table__tr">
                    <td class="table__td" valign="top" align="right">
                      Beneficiary:
                    </td>
                    <td class="table__td" valign="top">
                      <strong>
                        ArborCrowd Holding Company, LLC
                      </strong><br>
                      333 Earle Ovington Blvd.<br>
                      Suite 900<br>
                      Uniondale, NY 11553
                    </td>
                  </tr>
                  <tr class="table__tr">
                    <td class="table__td" valign="top" align="right">
                      Beneficiary Account #:
                    </td>
                    <td class="table__td" valign="top">
                      <strong>
                        7528417986
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <RadioButton
                v-model="funding_method"
                name="funding_method"
                value="funding_wire"
                label="SENT BY WIRE"
                class="small-mt-20 small-mb-20"
              />
            </div>
          </div>
        </div>

        <div v-if="ui_state.visible_method == 'check'" class="tabs__tab">
          <div class="row collapse">
            <div class="column">
              <p>Mail checks to:</p>
              <p class="box__text">
                ArborCrowd Investor Relations<br>
                Attention: ArborCrowd Investor Relations - Funding Group<br>
                333 Earle Ovington Blvd.<br>
                Suite 900<br>
                Uniondale, NY 11553<br><br>
                Payable to: ArborCrowd Holding Company, LLC<br>
                Funding Note: ONLY BANK OFFICIAL CHECKS ARE ACCEPTABLE
              </p>
              <RadioButton
                v-model="funding_method"
                name="funding_method"
                value="funding_check"
                label="SENT BY MAIL"
                class="small-mt-20 small-mb-20"
                data-cy="submit-offer__button"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="submit-offer__actions hide-on-disabled small-mt-8">
        <div class="row">
          <div class="small-6 column text-left">
            <button
              type="button"
              class="submit-offer__actions-button button button--hollow"
              :disabled="tab_error"
              @click="back"
            >
              Back
            </button>
          </div>
          <div class="small-6 column text-right">
            <button
              :disabled="funding_method === 'funding_unknown' || tab_error || funding_confirmed"
              type="button"
              class="submit-offer__actions-button button"
              data-cy="submit-offer__submit-button"
              @click="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint vue/no-v-html: "off" */

import { currency } from 'shared/view-filters';
import { default as RadioButton } from './radio-button.vue';
import { default as StepMessage } from './step-message.vue';

export default
  ({
    name: "FundingTab",
    components: {
      RadioButton,
      StepMessage
    },
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        funding_method: this.vdata.offer.funding_method,
        deal_card_html: window.deal_card_html,
        ui_state: {
          visible_method: ''
        }
      }
    },
    computed: {
      funding_confirmed() {
        return( this.vdata.offer.funding_method != "funding_unknown" );
      },
      show_deal_card() {
        return( ["awaiting_funds", "funds_received"].includes(this.vdata.offer.investment_stage) );
      },
      tab_class(){
        return { "submit-offer__tab--disabled": this.vdata.ui_state.tab_states.funding.disabled }
      },
      tab_error(){
        return null
      }
    },
    methods: {
      back(){
        this.vdata.controller.goto_step('accred');
      },
      currency,
      submit(){
        this.vdata.offer.funding_method = this.funding_method;
        this.vdata.controller.push_and_fetch_data()
        .then(()=> {
          $("html, body").animate({ scrollTop: 0}, 500);
        });
      },
      toggle_tab(method) {
        if (this.ui_state.visible_method == method) {
          this.ui_state.visible_method = ''
          return;
        }
        this.ui_state.visible_method = method;
      },
    }
  });
</script>
