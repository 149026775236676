<template>
  <div class="row small-collapse medium-uncollapse">
    <div class="column small-12 medium-9">
      <div class="investment-summary">
        <div class="investment-summary__row align-middle align-center">
          <div class="investment-summary__column">
            <div class="media__text media__text--summary-big" data-invested-indicator data-cy="investment-summary__invested">
              {{ currency(invested, 0) }}
            </div>
            <div class="progress progress--size-75 hide-for-medium">
              <div class="progress__indicator" style="width: 100%" />
            </div>
            <div class="media__text media__text--summary-label">
              Total Invested
            </div>
          </div>
          <div class="investment-summary__column-wide position-relative">
            <div class="media__divider media__divider--summary media__divider--left" />
            <div class="investment-summary__row align-middle">
              <div class="investment-summary__column-label">
                <div class="media__text media__text--summary" data-distributed-indicator data-cy="investment-summary__distributed">
                  {{ currency(distributed, 0) }}
                </div>
                <div class="progress hide-for-medium">
                  <div class="progress__indicator" :style="{width: invested_progress + '%'}" />
                </div>
                <div class="media__text media__text--summary-label">
                  Total Distributed
                </div>
              </div>
              <div class="investment-summary__column-donut">
                <GraphDonut :fill="invested_progress" />
              </div>
            </div>
          </div>
          <div class="investment-summary__column-wide position-relative">
            <div class="media__divider media__divider--summary media__divider--left" />
            <div class="investment-summary__row align-middle">
              <div class="investment-summary__column-label">
                <div class="media__text media__text--summary" data-capital-balance-indicator data-cy="investment-summary__capital">
                  {{ currency(capital_balance, 0) }}
                </div>
                <div class="progress hide-for-medium">
                  <div class="progress__indicator" :style="{width: capital_progress + '%'}" />
                </div>
                <div class="media__text media__text--summary-label">
                  Capital Balance
                </div>
              </div>
              <div class="investment-summary__column-donut">
                <GraphDonut :fill="capital_progress" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column small-12 medium-3">
      <div class="investment-summary small-pt-2 small-pb-9 medium-pb-0 medium-pt-10 medium-pr-24 large-pt-7 large-pl-17">
        <div class="media__section">
          <div class="row">
            <div class="column small-11 medium-12">
              <div class="row hide-for-medium">
                <div class="small-12 column">
                  <strong>{{ active_investment_count }}</strong> Active <span class="text text-cyan small-ml-5 small-mr-5">|</span>
                  <strong>{{ realized_investment_count }}</strong> Realized <span class="text text-cyan small-ml-5 small-mr-5">|</span>
                  <strong>{{ filtered_investments.length }}</strong> Total
                </div>
              </div>
              <div class="row small-pt-3 medium-pt-4 large-pt-18 xlarge-pt-14 small-pb-10 show-for-medium">
                <div class="column small-3 medium-6 position-relative small-mb-8">
                  <div class="media__text media__text--summary-label">
                    Active
                  </div>
                </div>
                <div class="column medium-6 text-right show-for-medium small-mb-8" data-cy="investment-summary__active">
                  <div class="media__text media__text--summary-label">
                    {{ active_investment_count }}
                  </div>
                </div>
                <div class="column small-3 medium-6 small-mb-8">
                  <div class="media__text media__text--summary-label">
                    Realized
                  </div>
                </div>
                <div class="column medium-6 text-right show-for-medium small-mb-8" data-cy="investment-summary__realized">
                  <div class="media__text media__text--summary-label">
                    {{ realized_investment_count }}
                  </div>
                </div>
                <div class="column small-3 medium-6 position-relative">
                  <div class="media__text media__text--summary-label">
                    <strong>Total</strong>
                  </div>
                </div>
                <div class="column medium-6 text-right show-for-medium" data-cy="investment-summary__total">
                  <div class="media__text media__text--summary-label">
                    <strong>{{ filtered_investments.length }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { default as GraphDonut } from './components/graph-donut.vue';
import { currency } from 'shared/view-filters';

    const props = defineProps({
      vdata: {
        type: Object,
        required: true
      },
    }),
    filtered_investments = computed(() => {
      if(props.vdata.active_investment){
        return props.vdata.investments.filter( (e)=> e.entity_id === props.vdata.active_investment );
      }else{
        return props.vdata.investments;
      }
    }),
    invested = computed(() => {
      return filtered_investments.value.reduce( (memo, inv) => { return memo + inv.invested }, 0);
    }),
    distributed = computed(() => {
      return filtered_investments.value.reduce( (memo, inv) => { return memo + inv.distributed }, 0);
    }),
    capital_balance = computed(() => {
      return filtered_investments.value.reduce( (memo, inv) => { return memo + inv.capitalBalance }, 0);
    }),
    invested_progress = computed(() => {
      if(distributed.value === 0){
        return 0;
      }else{
        let raw = (distributed.value / invested.value) * 100;
        return( Math.round(Math.min(100, raw)) );
      }
    }),
    capital_progress = computed(() => {
      return(Math.round(100 - invested_progress.value));
    }),
    active_investment_count = computed(() => {
      return filtered_investments.value.filter( (e)=> e.status === "closed" ).length;
    }),
    realized_investment_count = computed(() => {
      return filtered_investments.value.filter( (e)=> e.status === "realized" ).length;
    });

</script>
