/*
 *
 * @file header
 *
 */

class Header {

  constructor(app) {

    this.init = this.init.bind(this);
    this.header_menu = this.header_menu.bind(this);
    this.hide_on_scroll = this.hide_on_scroll.bind(this);
    this.app = app;

    //#
    // DOM Elements
    // @type {string}
    //#
    this.el = {
      body:              'body',
      header:            '[data-header]',
      header_nav:        '[data-header-nav]',
      header_nav_toggle: '[data-header-nav-toggle]'
    };

    //#
    // DOM Element Modifiers
    // @type {string}
    //#
    this.el_modifiers = {
      body: {
        header_is_hidden: 'body--header-is-hidden',
        lock_scroll:      'body--lock-scroll'
      },
      header: {
        hidden: 'header--hidden'
      },
      header_nav: {
        open: 'header__nav--open'
      },
      header_nav_toggle: {
        close: 'header__nav-toggle--close'
      }
    };

    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    if (!$(this.el.header).length) {
      return;
    }

    this.header_menu();
    this.hide_on_scroll();
  }

  header_menu() {

    $(document).off('click', this.el.header_nav_toggle);

    $(document).on('click', this.el.header_nav_toggle, () => {

      $(this.el.header_nav).toggleClass(this.el_modifiers.header_nav.open);
      $(this.el.header_nav_toggle).toggleClass(this.el_modifiers.header_nav_toggle.close);
      $(this.el.body).toggleClass(this.el_modifiers.body.lock_scroll);

    });

  }

  hide_on_scroll() {

    const delta = 5;
    let did_scroll = false;
    const height = $(this.el.header).outerHeight();
    let last_scroll_top = 0;

    const has_scrolled = () => {
      const st = $(document).scrollTop();

      if (Math.abs(last_scroll_top - st) <= delta) {
        return;
      }

      if ((st > last_scroll_top) && (st > height)) {
        $(this.el.header).addClass(this.el_modifiers.header.hidden);
        $(this.el.body).addClass(this.el_modifiers.body.header_is_hidden);
      } else {

        if ((st + $(window).height()) < $(document).height()) {
          $(this.el.header).removeClass(this.el_modifiers.header.hidden);
          $(this.el.body).removeClass(this.el_modifiers.body.header_is_hidden);
        }
      }

      return last_scroll_top = st;
    };

    $(window).scroll( ()=> did_scroll = true);

    setInterval((function() {
      if (did_scroll) {
        has_scrolled();
        return did_scroll = false;
      }
    }), 250);
  }
}

export default Header;
