<template>
  <div class="row align-right" data-cy="activity-log">
    <div v-if="show_any_filters" class="column shrink">
      <div class="table__filters table__filters--radius">
        <div class="row align-right">
          <div v-if="show_investment_filter" class="column shrink">
            <label class="filter__label">Filter By</label>
            <select class="filter select" data-cy="activity-log__investment-filter" @change="set_filter_investments">
              <option value="" :selected="params.deal == undefined">
                Investments
              </option>
              <option v-for="i in investments" :key="i.deal_id" :value="i.deal_id">
                {{ i.deal_name }}
              </option>
            </select>
            <div :class="[ show_investment_filter && show_entity_filter ? 'small-mb-10 medium-mb-0' : '' ]" />
          </div>
          <div v-if="show_entity_filter" class="column shrink">
            <label class="filter__label">Filter By</label>
            <select class="filter select" data-cy="activity-log__entity-filter" @change="set_filter_entities">
              <option :value="undefined" :selected="params.entity == undefined">
                Entities
              </option>
              <option v-for="e in entities" :key="e.entity_id" :value="e.entity_id">
                {{ e.legal_entity_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="small-12 column">
      <div
        ref="table_wrapper"
        class="table__wrapper"
        :style="{height: table_height + 'px'}"
        data-cy="activity-log__wrapper"
      >
        <table class="table">
          <thead class="table__thead">
            <tr class="table__tr">
              <th class="table__th" width="15%">
                Date
              </th>
              <th class="table__th" width="15%">
                Activity
              </th>
              <th class="table__th" width="33%">
                Message
              </th>
              <th
                v-if="!deal"
                class="table__th"
                width="15%"
                data-cy="activity-log__column"
              >
                Investment
              </th>
              <th class="table__th" width="22%" data-cy="activity-log__column">
                Entity
              </th>
            </tr>
          </thead>
          <TBody :result="events" :show_investment="!deal" />
        </table>
        <div ref="load_trigger" class="load-trigger" />
      </div>
      <div v-if="show_view_all_link" class="small-pt-20 text-right">
        <a href="/investor/portfolio/activity" class="link link--underline" data-cy="activity-log__view-all-link">
          <span class="link__text">
            View All Activity
          </span>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import { default as TBody } from './components/activity-t-body.vue';
import debounce from 'debounce';
import EventBus from 'shared/event-bus';

export default
  ({
    name: "ActivityLog",
    components: {
      TBody
    },
    props: {
      endpoint: {
        type: String,
        required: true
      },
      deal: {
        type: Number,
        default: undefined
      },
      deal_filter: {
        type: [Boolean, String],
        default: false
      },
      entity_filter: {
        type: [Boolean, String],
        default: false
      },
      entity: {
        type: [Number, undefined],
        default: undefined
      },
      show: {
        type: [Number, String],
        default: undefined
      },
      view_all_link: {
        type: [Boolean, String],
        default: false
      },
      allow_load_on_scroll: {
        type: Boolean,
        default: true
      },
      heights: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        ui_state: {
          fetching: false,
          replace_on_receive: false,
          has_more: true
        },
        events: [],
        investments: false,
        entities: false,
        total_events: 0,
        before: null,
        observer_callbacks: 0,
        table_height: 0,
        params: {
          show: this.$props.show ? this.$props.show : 0,
          deal: this.$props.deal || undefined,
          entity: this.$props.entity || undefined
        }
      };
    },
    computed: {
      show_any_filters() {
        return (this.show_investment_filter || this.show_entity_filter);
      },
      show_investment_filter() {
        return (this.investments.length > 1) && this.deal_filter;
      },
      show_entity_filter() {
        return (this.entities.length > 1) && this.entity_filter;
      },
      show_view_all_link() {
        return (this.total_events > this.params.show) && this.view_all_link;
      }
    },
    watch: {
      params: {
        handler:  function(){
          this.ui_state.replace_on_receive = true;
          this.ui_state.has_more = true;
          this.before = null;
          this.fetch();

        },
        deep: true
      },
      entity() {
        this.params.entity = this.entity;
      }
    },
    beforeDestroy() {
      this.observer.disconnect();
      $(window).off('resize', this.calc_height)
      EventBus.$off('portfolio:change_entity', this.set_filter_entities)
    },
    mounted() {
      let options = {
        root: this.$refs.table_wrapper,
        rootMargin: '0px',
        threshold: 0.5
      }
      this.observer = new IntersectionObserver(debounce(this.load_on_scroll, 500, true), options);
      this.fetch();
      if(this.allow_load_on_scroll){
        this.observer.observe(this.$refs.load_trigger);
      }
      $(window).on('resize', this.calc_height);
      EventBus.$on('portfolio:change_entity', this.set_filter_entities);
      this.calc_height();
    },
    methods: {
      calc_height(){
        if($(window).width() < 1024){
          this.table_height = this.heights.small;
        }else{
          this.table_height = this.heights.large;
        }
      },
      load_on_scroll(){
        if(this.observer_callbacks < 1){
          this.observer_callbacks++; // Prevents re-fetching on component mount, since the observer callback is triggered when it is first set
        }else{
          this.fetch();
          this.refetch = false;
        }
      },
      fetch() {
        if (this.ui_state.fetching) {
          this.refetch = true;
          return;
        }
        if (!this.ui_state.has_more){
          return;
        }
        this.ui_state.fetching = true;
        return new Promise((resolve, reject)=> {
          // prepare the payload by converting the needed attributes to a plain JS object
          const payload = JSON.parse(JSON.stringify(this.params));
          payload.before = this.before; // 'before' can't be in the params object because it is changed on ajax receive and would trigger a loop in the watch function
          $.ajax({
              url: this.endpoint,
              type: "GET",
              data: payload,
              dataType: 'json',
              success: this.receive.bind(this, resolve, reject),
              error: this.fetch_failure.bind(this, reject)
          });
        });
      },

      // The payload that comes back from the server has structure:
      // result: []
      // errors: []
      // has_more: boolean
      receive(resolve, reject, response){
        this.ui_state.fetching = false;
        if (!this.investments) { this.investments = response.investments; }
        if (!this.entities) { this.entities = response.entities; }
        if(this.ui_state.replace_on_receive){
          this.events = response.events;
          this.ui_state.replace_on_receive = false;
        }else{
          this.events = this.events.concat(response.events);
        }
        if(this.events.length){
          this.before = this.events[this.events.length - 1].timestamp;
        }else{
          this.before = null;
        }

        this.total_events = response.event_count;
        this.ui_state.has_more = this.total_events > this.events.length;

        if (this.refetch) {
          this.refetch = false;
          console.log("refetching");
          this.fetch();
        }
        resolve(response);
      },

      fetch_failure(reject, jqXHR){
        this.ui_state.fetching = false;
        alert('There was a problem fetching data');
        console.log(jqXHR);
        reject();
      },

      set_filter_entities(s){
        this.params.entity = s.target.value;
      },

      set_filter_investments(s){
        this.params.deal = s.target.value;
      }
    }
  });
</script>

<style scoped>
.load-trigger {
  height: 10px;
  width: 100%;
}
</style>
