<template>
  <tbody class="table__tbody">
    <tr
      v-for="row in result"
      :key="row.id"
      class="table__tr"
      :class="{ 'table__tr--new': !row.seen_by_user }"
      data-cy="activity-log__row"
    >
      <td class="table__td table__td--nowrap">
        {{ ac_medium_date(new Date(row.timestamp * 1000)) }}
      </td>
      <td class="table__td">
        {{ row.event_type }}
      </td>
      <td class="table__td">
        {{ row.message }}
      </td>
      <td v-if="show_investment" class="table__td">
        <a :href="row.investment_link" data-cy="activity-log__investment-link">{{ row.event_investment }}</a>
      </td>
      <td class="table__td">
        <a v-if="row.entity_link != ''" :href="row.entity_link" data-cy="activity-log__entity-link">
          {{ row.event_entity }}
        </a>
        <span v-else>
          {{ row.event_entity }}
        </span>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { ac_medium_date } from 'shared/view-filters';

export default {
  name: 'TBody',
  props: {
    result: {
      type: Array,
      required: true
    },
    show_investment: Boolean
  },
  methods: {
    ac_medium_date,
    format: require('date-fns').format
  }
};
</script>
