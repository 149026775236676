<template>
  <div
    class="checkbox"
    :class="{ [`checkbox--${size}`]: size, [`checkbox--disabled`]: disabled }"
  >
    <label>
      <input
        v-model="model"
        type="checkbox"
        class="checkbox__input"
        :disabled="disabled"
      />
      <span class="checkbox__indicator checkbox__indicator--white" />
      <span v-if="label" class="checkbox__input-label">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script setup>
  const props = defineProps({
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [Boolean, String],
      default: false,
    },
    size: {
      type: [Boolean, String],
      default: false,
    },
  }), model = defineModel();
</script>
