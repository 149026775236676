<template>
  <div class="callout callout--hidden callout--submit-flow" data-cy="submit-offer__step-message">
    <div v-if="message" class="callout__text" v-html="message" />
  </div>
</template>

<script>
/* eslint vue/no-v-html: "off" */

export default {
  name: 'StepMessage',
  props: {
    message: {
      type:    [Boolean, String],
      default: false
    }
  }
};
</script>
