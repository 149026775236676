<template>
  <div class="radio-button" :class="{ 'radio-button__inline': !expanded }">
    <label>
      <input
        v-model="model"
        :name="name"
        :value="value"
        class="radio-button__input"
        type="radio"
      />
      <span
        v-if="label"
        class="radio-button__label button"
        :class="button_class"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    value: {
      type: String,
      required: true,
    },
    label: {
      type: [Boolean, String],
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
  }),
  model = defineModel(),
  button_class = computed(() => {
    if (model.value === props.value) {
      return "";
    } else {
      return "button--hollow";
    }
  });
</script>
