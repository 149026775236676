/*
 *
 * @file document-list
 *
 */

import { createApp } from "vue";
import { configureVueAppDefaults } from 'shared/vue-configured';

const App = require('./document-list.vue').default;

class DocumentList {

  constructor(app, config) {
    this.init = this.init.bind(this);
    this.app = app;
    this.config = config;
    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    if (!$("[data-vue-target=document-list]").length) {
      return;
    }
    const $el = $('[data-vue-target=document-list]');
    const propsData = {
      endpoint: $el.data('endpoint'),
      filters:  $el.data('filters'),
      offer:    $el.data('offer'),
      title:    $el.data('title'),
      heights:  $el.data('heights')
    };
    this.vm = createApp(App, propsData);
    configureVueAppDefaults(this.vm);
    this.vm.mount("[data-vue-target=document-list]");
    // this.vm = new Vue({
    //   el: '[data-vue-target=document-list]',
    //   components: { App },
    //   render(createElement) {
    //     return createElement(App, {props: propsData});
    //   }
    // });

    window._vm = this.vm;
  }
}

export default DocumentList;
