<template>
  <div class="submit-offer">
    <div class="panel panel--large">
      <div class="panel__header">
        <div class="deal-container">
          <div class="column-deal-headlines">
            <div v-if="vdata.deal.deal_status === 'open_early_access'" class="stats__tag small-mb-10">
              Early Access
            </div>
            <h1 class="panel__heading">
              {{ vdata.deal.name }}
            </h1>
            <div class="stats__location small-mb-5">
              {{ vdata.deal.deal_display_location }}
            </div>
            <p v-if="['open_waitlist_1', 'open_waitlist_2'].includes(vdata.deal.deal_status)" class="stats__message">
              This deal is currently waitlisting new offers
            </p>
            <a :href="vdata.ui_defs.deal_page_url" class="link link--underline link--small">
              <span class="link__icon icon-chevron-left-small" />
              <span class="link__text">
                Back to Deal Details
              </span>
            </a>
          </div>
          <div class="column-deal-status">
            <Stats :vdata="vdata" />
          </div>
        </div>
      </div>

      <div class="panel__section">
        <Steps :vdata="vdata" />
      </div>

      <AmountTab :vdata="vdata" />
      <EntityTab :vdata="vdata" />
      <SubdocsTab :vdata="vdata" />
      <AccredTab :vdata="vdata" />
      <FundingTab :vdata="vdata" />

      <iframe
        id="docusign_embed"
        data-cy="submit-offer__docusign-embed"
        :class="{active: vdata.ui_state.docusign_iframe_url}"
        :src="vdata.ui_state.docusign_iframe_url ? vdata.ui_state.docusign_iframe_url : ''"
      />
    </div>

    <div class="panel panel--large">
      <div class="panel__header panel__header--subpanel-pb-0">
        <div v-html="contact_card_html" />
      </div>
    </div>

    <NoticeModal
      :show="vdata.ui_state.show_notice_modal"
      :notice="vdata.ui_state.notice"
      :callback="resolve_notice"
      size="small"
      @close="close_notice_modal"
    />
  </div>
</template>

<script>
/* eslint vue/no-v-html: "off" */

import { default as AccredTab } from './components/04-accred-tab.vue';
import { default as AmountTab } from './components/01-amount-tab.vue';
import { default as EntityTab } from './components/02-entity-tab.vue';
import { default as FundingTab } from './components/05-funding-tab.vue';
import { default as NoticeModal } from '../shared/notice-modal.vue';
import { default as Stats } from './components/stats.vue';
import { default as Steps } from './components/steps.vue';
import { default as SubdocsTab } from './components/03-subdocs-tab.vue';
import EventBus from '../shared/event-bus';

export default
  ({
    name: "SubmitOffer",
    components: {
      AccredTab,
      AmountTab,
      EntityTab,
      FundingTab,
      NoticeModal,
      Stats,
      Steps,
      SubdocsTab
    },
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        contact_card_html: window.contact_card_html,
        resolve_notice() {}
      };
    },
    mounted() {
      EventBus.$on('submit-offer-notice', this.handle_notice);
    },
    destroyed() {
      EventBus.$off('submit-offer-notice', this.handle_notice);
    },
    methods: {
      close_notice_modal() {
        this.vdata.ui_state.show_notice_modal = false;
      },
      handle_notice(notice, callback) {
        return new Promise((resolve)=> {
          this.resolve_notice = resolve; //will be fired by modal onclose
          this.vdata.ui_state.notice = notice;
          // const later_callback = callback;
          this.vdata.ui_state.show_notice_modal = true;
      }).then(() => {
          this.close_notice_modal();
          if (callback != null) { return callback(); }
        });
      }
    }
  });
</script>
