<template>
  <div>
    <div class="tabs">
      <div class="row collapse" :class="['large-up-' + ui_state.tabs.length]">
        <div v-for="tab in ui_state.tabs" :key="tab.type" class="column column-block">
          <button
            type="button"
            class="tabs__button"
            :class="{'tabs__button--active': tab.type == ui_state.current_tab}"
            data-cy="documents__tab"
            @click="set_document_type(tab.type)"
          >
            <span class="tabs__button-text">
              {{ tab.label }}
            </span>
            <span v-if="new_items[tab.type] > 0" class="tabs__button-count">
              {{ new_items[tab.type] }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!filters" class="tabs__spacer" />
    <div v-if="filters" class="table__filters">
      <div class="row align-right">
        <div v-if="show_investment_filter" class="column shrink">
          <label class="filter__label">Filter by</label>
          <select v-model="params.investment" class="filter select" data-cy="documents__investment-filter">
            <option :value="undefined" :selected="params.investment == undefined">
              Investments
            </option>
            <option v-for="l in investments" :key="l.deal_id" :value="l.deal_id">
              {{ l.deal_name }}
            </option>
          </select>
          <div :class="[ show_investment_filter && show_entity_filter ? 'small-mb-10 medium-mb-0' : '' ]" />
        </div>
        <div v-if="show_entity_filter" class="column shrink">
          <label class="filter__label">Filter by</label>
          <select v-model="params.entity" class="filter select" data-cy="documents__entity-filter">
            <option :value="undefined" :selected="params.entity == undefined">
              Entities
            </option>
            <option v-for="l in entities" :key="l.entity_id" :value="l.entity_id">
              {{ l.legal_entity_name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="table__wrapper" :style="{height: table_height + 'px'}">
      <table class="table">
        <thead class="table__thead">
          <tr>
            <th class="table__th" data-cy="documents__column">
              Date
              <Sorter
                :set_sort="set_sort"
                column="date"
                :order="params.order"
                :order-by="params.order_by"
              />
            </th>
            <th class="table__th" data-cy="documents__column">
              Name
              <Sorter
                :set_sort="set_sort"
                column="name"
                :order="params.order"
                :order-by="params.order_by"
              />
            </th>
            <td
              v-for="column in columns"
              :key="column.label"
              class="table__th"
              data-cy="documents__column"
            >
              {{ column.label }}
            </td>
          </tr>
        </thead>
        <TBody :result="offer_documents" :columns="columns" />
      </table>
    </div>

    <div class="row">
      <div class="column small-12 text-right small-mt-20">
        <a class="link link--underline">
          <span class="link__icon icon-download" />
          <span
            class="link__text"
            data-cy="documents__download_all"
            @click="download_all(params.investment)"
          >Download All
          </span>
        </a>
      </div>
    </div>

    <NoticeModal
      :notice="ui_state.notice ? ui_state.notice : ''"
      :show="!!ui_state.notice"
      size="medium"
      :callback="hide_notice"
    />
  </div>
</template>


<script>
import { default as TBody } from './components/document-list-t-body.vue';
import { default as Sorter } from 'shared/sorter.vue';
import { default as NoticeModal } from 'shared/notice-modal.vue';

export default
  ({
    name: "DocumentList",
    components: {
      NoticeModal,
      TBody,
      Sorter
    },
    props: {
      endpoint: {
        type: String,
        required: true
      },
      filters: Boolean,
      title: {
        type: String,
        required: true
      },
      show_investment_column: {
        type: Boolean,
        default: true
      },
      entity: {
        type: Number,
        default: undefined
      },
      heights: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        ui_state: {
          fetching: false,
          tabs: [
            { type: 'all', label: 'all docs' },
            { type: 'quarterly_report', label: 'quarterly reports' },
            { type: 'tax', label: 'tax docs' },
            { type: 'subscription', label: 'sub docs' },
            { type: 'accreditation', label: 'verification docs' },
            { type: 'update_document', label: 'other docs' }
          ],
          current_tab: 'all',
          notice: false
        },
        offer_documents: [],
        new_items: {
          all: 0,
          accreditation: 0,
          quarterly_report: 0,
          subscription: 0,
          tax: 0,
          update_document: 0
        },
        investments: false,
        entities: false,
        table_height: 0,
        params: {
          investment: undefined,
          entity: undefined,
          document_type: 'all',
          order_by: 'date',
          order: 'desc'
        }
      };
    },
    computed: {
      show_investment_filter() {
        return this.investments.length > 1;
      },
      show_entity_filter() {
        return (this.entities.length > 1) && !['quarterly_report', 'update_document'].includes(this.params.document_type);
      },

      columns() {
        let cols;
        switch (this.$data.params.document_type) {
          case 'all':
            cols = [
              { field: "legal_entity_name", label: "Entity", width: "25%" }
            ];
            break;
          case 'quarterly_report':
            cols = [
              { field: "quarter", label: "Quarter", width: "10%" },
              { field: "fiscal_year", label: "Fiscal Year", width: "15%" }
            ];
            break;
          case 'tax':
            cols = [
              { field: "legal_entity_name", label: "Entity", width: "25%" },
              { field: "entity_type", label: "Entity Type", width: "15%" },
              { field: "fiscal_year", label: "Fiscal Year", width: "10%" }
            ];
            break;
          case 'subscription':
            cols = [
              { field: "legal_entity_name", label: "Entity", width: "25%" },
              { field: "entity_type", label: "Entity Type", width: "25%" },
              //{ field: "submission_method", label: "Method", width: "10%" }
            ];
            break;
          case 'accreditation':
            cols = [
              { field: "expiration_date", label: "Expiration Date", width: "15%" },
              { field: "legal_entity_name", label: "Entity", width: "35%" },
              //{ field: "submission_method", label: "Method", width: "10%" }
            ];
            break;
          case 'update_document':
            cols = [];
            break;
        }
        if (this.$props.show_investment_column && (this.$data.params.document_type !== 'accreditation')) {
          cols.unshift({ field: "investment", label: "Investment", width: "20%" });
        }
        return cols;
      }
    },
    beforeDestroy() {
      $(window).off('resize', this.calc_height)
    },
    mounted() {
      this.$watch(
        function() {
          return this.params;
        },
        this.fetch,
        {deep: true}
      );
      this.fetch();
      $(window).on('resize', this.calc_height);
      this.calc_height();
    },
    methods: {
      after_download(response){
        this.ui_state.notice = response.message
      },
      calc_height(){
        if($(window).width() < 1024){
          this.table_height = this.heights.small;
        }else{
          this.table_height = this.heights.large;
        }
      },
      download_all(){
        const payload = JSON.parse(JSON.stringify(this.params));
        $.ajax({
            url: `${this.endpoint}/download`,
            type: "GET",
            data: payload,
            dataType: 'json',
            success: this.after_download
        });
      },
      fetch() {
        if (this.ui_state.fetching) {
          this.refetch = true;
          return;
        }
        this.ui_state.fetching = true;
        return new Promise((resolve, reject)=> {
          // prepare the payload by converting the needed attributes to a plain JS object
          const payload = JSON.parse(JSON.stringify(this.params));
          $.ajax({
              url: this.endpoint,
              type: "GET",
              data: payload,
              dataType: 'json',
              success: this.receive.bind(this, resolve, reject),
              error: this.fetch_failure.bind(this, reject)
          });
        });
      },
      hide_notice(){
        this.ui_state.notice = false;
      },

      // The payload that comes back from the server has structure:
      // investments: []
      // entities: []
      // documents: []
      // new_items: {}
      receive(resolve, reject, response){
        this.ui_state.fetching = false;
        if (this.refetch) {
          this.refetch = false;
          this.fetch();
        }
        if (!this.investments) { this.investments = response.investments; }
        if (!this.entities) { this.entities = response.entities; }
        this.offer_documents = response.documents;
        this.new_items = response.new_items;
        resolve(response);
      },

      fetch_failure(reject, jqXHR){
        this.ui_state.fetching = false;
        alert('There was a problem fetching data');
        console.log(jqXHR);
        return reject();
      },

      set_document_type(type){
        this.ui_state.current_tab = type;
        this.params.document_type = type;
      },

      set_filter_entities(s){
        this.params.entity = s.target.value;
      },

      set_filter_investments(s){
        this.params.offer = s.target.value;
      },

      set_sort(column){
        if(this.params.order_by === column){
          if(this.params.order === "asc"){
            this.params.order = "desc";
          }else{
            this.params.order= "asc";
          }
        }else{
          this.params.order_by = column;
        }
      },

      sort_class(column, dir){
        if(this.params.order_by === column && this.params.order === dir){
          return({"active": true});
        }else{
          return({});
        }
      }
    }
  });

</script>

<style scoped>
a.active{
  color:red!important;
}
  .tabs .column {
    margin-bottom: 0;
  }

  .tabs .button {
    background-color: #F2F2F2;
    color: #000;
    outline: none;
  }

  .tabs .button.active {
    background-color: #004A3d;
    color: #fff;
  }

  .tabs .button.active:focus,
  .tabs .button.active:hover {
    background-color: #007d67;
  }

  .button {
    position: relative;
  }

  .temp__count {
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
    background-color: #000;
    color: white;
    font-size: 16px;
    font-style: normal;
    right: -7px;
    top: -12px;
    z-index: 1;
  }
</style>
