<template>
  <div
    v-if="vdata.ui_state.current_tab == 'amount'"
    class="submit-offer__tab submit-offer__tab--amount"
    data-cy="submit-offer__tab"
    :class="tab_class"
  >
    <div class="panel__section">
      <StepMessage :message="vdata.ui_state.tab_states.amount.message" />
      <h2 class="submit-offer__heading">
        Submit an Offer
      </h2>

      <p>
        Submit an offer to invest in {{ vdata.deal.name }}
      </p>

      <div class="callout">
        <label class="callout__label" for="offer_amount">
          Offer Amount
        </label>
        <currency-input
          id="offer_amount"
          v-model="vdata.offer.amount_submitted"
          class="callout__input small-mb-15"
          :class="{ 'error': offer_is_invalid.length > 0 }"
          required
          type="text"
          data-cy="submit-offer__amount"
          :readonly="vdata.controller.offer_is_pre_approved(vdata.offer)"
          @keyup="validate_offer"
        />
        <span
          v-for="error in offer_is_invalid"
          :key="error"
          class="form-error is-visible"
          data-cy="form__error"
          role="alert"
        >
          {{ error }}
        </span>
        <span class="callout__label callout__label--small">
          Minimum {{ currency(vdata.deal.minimum_offer) }}
        </span>
      </div>

      <h3 class="submit-offer__subheading">
        Disclaimer
      </h3>

      <div class="callout callout--scroll callout--secondary">
        <h6 class="callout__heading">
          Overview Disclosures/Disclaimers
        </h6>
        <p class="callout__text">
          This overview does not constitute an offer to sell you a security, or a solicitation of an offer by you to purchase a security by or with ArborCrowd Holding Company, LLC and/or any of its affiliates or subsidiaries (“ArborCrowd”). No offer to sell or solicitation of an offer to buy may be made prior to the delivery by the project sponsor of definitive documentation relating to a proposed investment (collectively, the “Offering Materials”), including, in most cases, (1) an offering circular or private placement memorandum describing the investment opportunity and the rights, preferences and obligations attached to the security being offered, (2) an operating or similar agreement governing operation of the business entity being formed and defining the rights of equity owners, and (3) a form of subscription agreement governing your formal offer to subscribe for an equity interest in an ArborCrowd project entity. You are urged to review carefully all offering materials that are provided to you by the project sponsor before making any investment decision. ArborCrowd does not provide tax, legal, investment or financial advice. You are advised to consult with your own tax, legal, financial and other advisers prior to making an investment. Past performance is not a guarantee of future results or success.
        </p>
        <p class="callout__text">
          The overview is also summary in nature, and does not purport to be complete.
        </p>
        <p class="callout__text">
          All offers and sales that are made through the ArborCrowd portal have not been registered under the United States Securities Act of 1933, as amended, or registered or qualified under applicable state securities laws. Neither the Securities and Exchange Commission nor any state regulatory authority has reviewed, approved or disapproved of any offers or sales, the related offering materials, or the terms of any offering, or determined whether the offering materials are truthful or complete. It is illegal for any person to tell you otherwise.
        </p>
        <h6 class="callout__heading">
          Indications of Interest
        </h6>
        <p class="callout__text">
          No money or binding legal commitment is being requested at this time. Offers and sales will only be made pursuant to the formal Offering Materials to be provided by the project sponsor. Investor commitments to purchase can only be made pursuant to reviewing, signing and submitting a subscription agreement, operating agreement, and private placement memorandum provided by ArborCrowd. ArborCrowd may accept or reject a prospective investor after review of the signed project documents in its sole and absolute discretion.
        </p>
        <h6 class="callout__heading">
          Electronic Delivery
        </h6>
        <p class="callout__text">
          I understand that ArborCrowd will use this portal to electronically deliver financial statements, reports, K-1 tax documents and other information for any and all of my investments with ArborCrowd, including investments made individually or via business entities (i.e., corporations, limited liability companies, trusts, etc.).
        </p>

        <h6 class="callout__heading">
          Confidentiality
        </h6>
        <p class="callout__text">
          ArborCrowd is a private company that operates in a highly competitive industry. Accordingly, ArborCrowd takes great precautions to protect its proprietary information and confidential documents concerning its business operations, financial information and prospective operations. Therefore, any and all information provided by ArborCrowd is strictly confidential, solely for the purpose of evaluating a potential purchase of units in a specific project and for no other purposes. With the sole exceptions of your officers, directors, members, owners, financial advisors, accountants or legal counsel, in each case as absolutely necessary to evaluate the possible transaction contemplated herein, the confidential information shall not be disclosed to any third party without the ArborCrowd’s written consent. By accepting receipt of this information, you acknowledge that the information provided to you shall be held in the strictest confidence, shall not be copied or disseminated in any manner except to the individuals mentioned above and shall be only used for the purpose for which it is intended.
        </p>
      </div>

      <div class="row align-center">
        <div class="column small-12 large-11">
          <p class="callout__text">
            By clicking the submit button below:
          </p>
          <Checkbox
            id="checkbox1"
            v-model="vdata.ui_state.disclaimer_1"
            data-disclaimer
            type="checkbox"
            data-cy="submit-offer__disclaimer-checkbox-one"
            label=" I acknowledge that I am providing ArborCrowd an indication of my interest to invest in this offering based on my review of the materials made available to me."
          />
          <Checkbox
            id="checkbox2"
            v-model="vdata.ui_state.disclaimer_2"
            data-disclaimer
            type="checkbox"
            data-cy="submit-offer__disclaimer-checkbox-two"
            label=" I have read the foregoing explanation and disclaimer and understand and agree to its contents, including that I will be asked to review, complete, execute and deliver additional materials, including a subscription agreement, operating agreement, and private placement memorandum before my commitment to invest will be complete, and no offer will be binding until it is accepted in ArborCrowd’s sole and absolute discretion."
          />
        </div>
      </div>

      <div class="submit-offer__actions">
        <div class="row">
          <div class="small-6 column text-left">
            <button
              v-if="!vdata.offer.id"
              type="button"
              class="submit-offer__actions-button submit-offer__actions-button--ignore-error  button button--hollow"
              @click="cancel"
            >
              Cancel
            </button>
          </div>
          <div class="small-6 column text-right">
            <button
              type="button"
              class="submit-offer__actions-button button"
              data-cy="submit-offer__submit-button"
              :disabled="tab_error"
              @click="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as Checkbox } from './checkbox.vue';
import { default as StepMessage } from './step-message.vue';
import { currency } from 'shared/view-filters';

export default
  ({
    name: "AmountTab",
    components: {
      Checkbox,
      StepMessage
    },
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        offer_is_invalid: []
      }
    },
    computed: {
      tab_class(){
        return {
          "submit-offer__tab--disabled": this.vdata.ui_state.tab_states.amount.disabled,
          "submit-offer__tab--error": this.tab_error
        }
      },
      tab_error() {
        return(this.vdata.offer.amount_submitted < this.vdata.deal.minimum_offer || this.vdata.offer.amount_submitted % 1000 || !this.vdata.ui_state.disclaimer_1 || !this.vdata.ui_state.disclaimer_2);
      }
    },
    methods: {
      currency,
      cancel(){
        window.location.href = this.vdata.ui_defs.deal_page_url;
      },
      submit(){
        this.vdata.controller.next_step();
      },
      validate_offer() {
        const minimum_error = `A minimum investment of ${currency(this.vdata.deal.minimum_offer)} is required.`
        const interval_error = "$1,000 investment increments required."
        let errors = [];
        if(this.vdata.offer.amount_submitted < this.vdata.deal.minimum_offer){
          errors.push(minimum_error);
        }
        if( this.vdata.offer.amount_submitted % 1000 ){
          errors.push(interval_error);
        }
        this.offer_is_invalid = errors;
      }
    }
  });
</script>
