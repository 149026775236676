<template>
  <div class="cta">
    <div class="cta__row row">
      <div v-if="items.learn" class="cta__column column small-5 large-12">
        <div class="cta__item">
          <div class="cta__item-text">
            Questions about this Step?
          </div>
          <!-- TODO #5: CTA link -->
          <a href="mailto:investorrelations@arborcrowd.com" class="cta__item-button button button--hollow">
            Contact Investor Relations
          </a>
        </div>
      </div>
      <div v-if="items.learn && items.relations" class="cta__column column small-2 hide-for-large">
        <div class="cta__divider" />
      </div>
      <div v-if="items.relations" class="cta__column column small-5 large-12">
        <div class="cta__item">
          <div class="cta__item-text">
            Need to make a change to your offer?
          </div>
          <!-- TODO #5: CTA link -->
          <a href="mailto:investorrelations@arborcrowd.com" class="cta__item-button button button--hollow">
            Contact Investor Relations
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA',
  props: {
    items: {
      type: Object,
      default: () => ({
        learn:     true,
        relations: true
      })
    }
  }
};
</script>
