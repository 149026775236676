/*
 *
 * @file docusign-embed
 * Handles the flash banner prompting other signatories to open the
 * embedded signing ceremony
 *
 */

class DocusignEmbed {

  constructor(app, config) {
    this.handler = this.handler.bind(this);
    this.receive_message = this.receive_message.bind(this);
    this.launch_embed = this.launch_embed.bind(this);
    this.hide_embed = this.hide_embed.bind(this);
    this.show_status = this.show_status.bind(this);
    this.app = app;
    this.config = config;
    window.addEventListener("message", this.receive_message, false);
    $(document).on('click', '[data-docusign-offer]', this.handler);
  }

  handler(e) {
    const offer_id = $(e.target).data('docusignOffer')
    this.launch_embed(offer_id)
  }

  launch_embed(offer_id) {
    $.getJSON(`/investor/offers/${offer_id}/sign`, (resp) => {
      $('#docusign_embed2')
        .addClass('active')
        .attr('src', resp.url)
    })
  }

  hide_embed(){
    $('#docusign_embed2')
      .removeClass('active')
      .attr('src', '')
  }

  receive_message(event) {
    if (event.origin === window.location.origin) {
      const data_type = event && event.data && event.data.type;
      let ds_event, offer_id;
      switch (data_type) {
        case "subdoc_sig_complete":
          ds_event = event.data.ds_event;
          offer_id = event.data.offer_id;
          if(ds_event == "ttl_expired"){ // the docusign embed URL is only valid for a few seconds. If it's expired (because of slowserver response we need to regenerate it.)
            this.launch_embed(offer_id);
          }else if( ds_event == "session_timeout" ){
            alert('Your docusign session timed out. Click OK to restart.');
            this.launch_embed(offer_id);
          }else if( ds_event == "signing_complete" || ds_event == "viewing_complete" ){
            // The Docusign API can take up to 60 seconds to communicate
            // the results via the webhook, and we don't want the flash prompt to remain
            // visible on the next page load if it happens before then, so we
            // force a DocuSign API pull here:
            $.get(`/investor/offers/${offer_id}/docusign_complete`)
            this.hide_embed();
            this.show_status(offer_id, "signed");
          }else if( ds_event == "cancel" ){
            // in this case, the user has clicked "finish later"
            this.hide_embed();
          }else if( ds_event == "decline" ){
            // in this case, the user has clicked "decline to sign"
            $.get(`/investor/offers/${offer_id}/docusign_complete`) // poll Docusign API, don't wait for their webhook
            this.hide_embed();
            this.show_status(offer_id, "declined");
          }else{
            throw new Error("Unknown Docusign status received post-embed: " + ds_event);
          }
      }
    }
  }

  show_status(offer_id, status){
    $(`[data-docusign-offer-prompt=${offer_id}] span`).addClass('hide')
    $(`[data-docusign-offer-prompt=${offer_id}] span[data-docusign-status=${status}]`).removeClass('hide')
  }
}

export default DocusignEmbed;
