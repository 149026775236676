<template>
  <div class="stats">
    <div v-if="show_any" class="stats__header">
      <h6 class="stats__heading">
        Offer Status:
        <span class="stats__heading--large text-green">
          {{ offer_status }}
        </span>
      </h6>
      <ul class="stats__list">
        <li class="stats__list-item">
          <span class="text-light">
            Amount:
          </span>
          <span class="text-green">
            {{ currency(vdata.offer.amount_submitted) }}
          </span>
        </li>
        <li v-if="vdata.entity.id" class="stats__list-item">
          <span class="text-light">
            Entity:
          </span>
          <span class="text-green">
            {{ vdata.entity.legal_entity_name }}
          </span>
        </li>
        <li v-if="show_subdocs" class="stats__list-item">
          <span class="text-light">
            Subscription Documents:
          </span>
          <span class="text-green">
            {{ vdata.ui_defs.document_statuses[vdata.offer.subscription_document.status] }}
          </span>
        </li>
        <li v-if="show_accred" class="stats__list-item">
          <span class="text-light">
            Investor Verification:
          </span>
          <span class="text-green">
            {{ vdata.ui_defs.document_statuses[vdata.accreditation.status] }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { currency } from 'shared/view-filters';

export default {
  name: 'Stats',
  props: {
    vdata: {
      type:     Object,
      required: true
    }
  },
  computed: {
    offer_status() {
      let status = this.vdata.ui_defs.offer_statuses[this.vdata.offer.investment_stage];
      if(this.vdata.offer.is_waitlisted){
        status = `Waitlisted, ${status}`;
      }
      return(status);
    },
    show_any() {
      return(this.vdata.ui_state.current_tab != 'amount');
    },
    show_subdocs() {
      return(this.vdata.offer.subscription_document.status != 'created');
    },
    show_accred() {
      return(this.vdata.accreditation);
    }
  },
  methods: {
    currency
  }
}
</script>
