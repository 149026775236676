/*
 *
 * @file activity-log
 * Present activity in /investor/portfolio and /investor/portfolio/activity
 *
 */

import { createApp } from "vue";
import { configureVueAppDefaults } from 'shared/vue-configured';
const App = require('./activity-log.vue').default;



class ActivityLog {

  constructor(app, config) {
    this.init = this.init.bind(this);
    this.app = app;
    this.config = config;
    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    if (!$("[data-vue-target=activity-log]").length) {
      return;
    }
    console.log("ActivityLog:init");
    const $el = $('[data-vue-target=activity-log]');
    const propsData = {
      allow_load_on_scroll: $el.data('loadOnScroll'),
      endpoint:             $el.data('endpoint'),
      deal:                 $el.data('deal'),
      deal_filter:          $el.data('dealFilter'),
      entity_filter:        $el.data('entityFilter'),
      show:                 $el.data('show'),
      entity:               $el.data('entity'),
      view_all_link:        $el.data('viewAllLink'),
      title:                $el.data('title'),
      heights:              $el.data('heights')
    };
    this.vm = createApp(App, propsData);
    configureVueAppDefaults(this.vm);
    this.vm.mount("[data-vue-target=activity-log]");
    // this.vm = new Vue({
    //   el: '[data-vue-target=activity-log]',
    //   components: { App },
    //   render(createElement) {
    //     return createElement(App, {props: propsData});
    //   }
    // });

    return window._vm = this.vm;
  }
}



export default ActivityLog;
