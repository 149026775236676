<template>
  <div
    v-if="vdata.ui_state.current_tab == 'subdocs'"
    class="submit-offer__tab submit-offer__tab--subdocs"
    data-cy="submit-offer__tab"
    :class="[tab_class, { 'submit-offer__tab--error': tab_error }]"
  >
    <div class="panel__section">
      <StepMessage
        v-if="vdata.ui_state.tab_states.subdocs.message != false"
        :message="vdata.ui_state.tab_states.subdocs.message"
      />

      <div class="row align-justify">
        <div class="column small-12 large-9">
          <h2 class="submit-offer__heading" data-cy="submit-offer__heading">
            Subscription Documents
          </h2>
          <p>
            Please review, sign and return your subscription documents. This
            confirms your agreement to the terms and conditions of the
            investment, including information about future distributions.
          </p>
          <p>
            There are two ways to complete your documents. You can
            electronically sign by choosing “Sign Electronically.” Or you can
            choose “Sign Manually” to download the document, manually sign it,
            and then upload, email or mail it back.
          </p>
        </div>
      </div>

      <div
        v-if="vdata.offer.subscription_document.status != 'received'"
        class="submit-offer__subtabs tabs"
      >
        <h3 class="submit-offer__subheading small-mb-30">
          Select a method to complete your Subscription Documents:
        </h3>

        <div class="row align-justify">
          <div v-if="show_docusign_controls" class="column tabs__title">
            <div
              class="tabs__title-content first"
              data-cy="submit-offer__tab-electronic"
            >
              <span class="submit-offer__subheading small-mb-14 display-block"
                >Sign Electronically (Preferred)</span
              >
              <p>{{ docusign_tab_message }}</p>
              <button
                type="button"
                class="button button--primary button--uppercase button--large"
                :class="{
                  'button--disabled':
                    vdata.ui_state.fetching ||
                    vdata.offer.subscription_document.status === 'other_sigs',
                }"
                :disabled="
                  vdata.ui_state.fetching ||
                  vdata.offer.subscription_document.status === 'other_sigs'
                "
                data-cy="submit-offer__button-docusign"
                @click="
                  vdata.controller.start_docusign_process() &&
                    toggle_tab('docusign')
                "
              >
                {{ vdata.ui_state.fetching ? "Processing..." : "Get Started" }}
              </button>
            </div>
          </div>
          <div
            class="column small-1 align-middle text-center small-pl-0 small-pr-0"
          >
            <span class="submit-offer__subheading display-block small-mt-30"
              >OR</span
            >
          </div>
          <div
            v-if="show_offline_controls"
            class="column tabs__title"
            :class="{
              'tabs__title--active': ui_state.visible_method == 'offline',
            }"
          >
            <div class="tabs__title-content" data-cy="submit-offer__tab-manual">
              <span class="submit-offer__subheading small-mb-14 display-block"
                >Sign Manually</span
              >
              <br class="hide-for-medium" />
              <p>
                Download, sign <br class="hide-for-medium" />and submit
                documents
              </p>
              <button
                type="button"
                class="button button--primary button--uppercase button--large"
                :class="{
                  'button--disabled': ui_state.visible_method == 'offline',
                }"
                data-cy="submit-offer__button"
                @click="toggle_tab('offline')"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
        <div v-if="ui_state.visible_method == 'offline'" class="tabs__tab">
          <div class="row collapse">
            <div class="column shrink">
              <h6 class="tabs__tab-heading small-pr-40">Step 1</h6>
            </div>
            <div class="column">
              <p class="tabs__tab-text tabs__tab-text--large">
                Download Subscription Documents
              </p>
              <a
                :href="subdoc_download_url"
                target="_blank"
                role="button"
                download
                class="button button--primary button--tab text-uppercase"
                data-turbolinks="false"
                data-cy="submit-offer__button"
                @click="delayed_push"
                >Download</a
              >
            </div>
          </div>

          <hr class="tabs__tab-hr" />

          <div class="row collapse">
            <div class="column shrink">
              <h6 class="tabs__tab-heading small-pr-40">Step 2</h6>
            </div>
            <div class="column">
              <p class="tabs__tab-text tabs__tab-text--large small-mb-0">
                Complete and sign the documents
              </p>
            </div>
          </div>

          <hr class="tabs__tab-hr" />

          <div class="row collapse">
            <div class="column shrink">
              <h6 class="tabs__tab-heading small-pr-40">Step 3</h6>
            </div>
            <div class="column">
              <p class="tabs__tab-text tabs__tab-text--large">
                Return signed Subscription Document:
              </p>

              <p class="tabs__tab-text small-mb-20">
                Upload signed subscription documents (preferred)
              </p>

              <Uploader
                :vdata="{
                  choose_label: 'Upload',
                  upload_label: 'Submit Document',
                  button_class: ghosted_upload
                    ? 'button button--primary button--tab'
                    : '',
                  endpoint: vdata.ui_state.subdoc_control_url,
                  onsuccess: submit,
                  mime_types: ['application/pdf'],
                }"
                @addedfile="upload_selected"
              />

              <hr
                class="tabs__tab-hr tabs__tab-hr--dashed small-mt-30 small-mb-15"
              />

              <p
                class="tabs__tab-text small-mb-0"
                :class="{ 'text-ghosted': ghosted_email }"
              >
                Or email your signed Subscription Document to
              </p>

              <p
                class="tabs__tab-text"
                :class="{ 'text-ghosted': ghosted_email }"
              >
                <a
                  href="mailto:subscriptions@arborcrowd.com"
                  class="text-orange"
                >
                  subscriptions@arborcrowd.com
                </a>
              </p>

              <RadioButton
                v-model="vdata.offer.subscription_document.submission_method"
                name="subdoc_submission_method"
                value="email"
                data-cy="subdocs__email-documents"
                label="SENT BY EMAIL"
                :expanded="false"
              />

              <hr
                class="tabs__tab-hr tabs__tab-hr--dashed small-mt-30 small-mb-15"
              />

              <p
                class="tabs__tab-text"
                :class="{ 'text-ghosted': ghosted_mail }"
              >
                Or mail your signed Subscription Document to
              </p>

              <p
                class="tabs__tab-text text-smaller"
                :class="{ 'text-ghosted': ghosted_mail }"
              >
                ArborCrowd Investor Relations<br />
                333 Earle Ovington Blvd., Suite 900<br />
                Uniondale, NY 11553
              </p>

              <RadioButton
                v-model="vdata.offer.subscription_document.submission_method"
                name="subdoc_submission_method"
                value="mail"
                data-cy="submit-offer__button"
                label="SENT BY MAIL"
                :expanded="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="submit-offer__actions small-mt-8">
        <div class="row">
          <div class="small-6 column text-left">
            <button
              v-if="!vdata.ui_state.tab_states.entity.disabled && !tab_error"
              type="button"
              class="submit-offer__actions-button button button--hollow"
              @click="back"
            >
              Back
            </button>
          </div>
          <div class="small-6 column text-right">
            <button
              type="button"
              class="submit-offer__actions-button button"
              data-cy="submit-offer__submit-button"
              :disabled="tab_error"
              @click="submit"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <CTA class="hide" />
    </div>
  </div>
</template>

<script>
import { default as CTA } from "./cta.vue";
import { default as RadioButton } from "./radio-button.vue";
import { default as StepMessage } from "./step-message.vue";
import { default as Uploader } from "shared/upload-file.vue";

export default {
  name: "SubdocsTab",
  components: {
    CTA,
    RadioButton,
    StepMessage,
    Uploader,
  },
  props: {
    vdata: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    let visible_method;
    switch (this.vdata.offer.subscription_document.submission_method) {
      case "unknown":
        visible_method = "";
        break;
      case "docusign":
        visible_method = "docusign";
        break;
      default:
        visible_method = "offline";
        break;
    }
    return {
      ui_state: {
        visible_method: visible_method,
      },
    };
  },
  computed: {
    docusign_tab_message() {
      if (this.vdata.offer.subscription_document.status === "other_sigs") {
        return "Your signature has been recorded and your subscription document is awaiting signatures from additional parties.";
      } else {
        return "Launch your DocuSign session now";
      }
    },
    ghosted_upload() {
      return (
        this.vdata.offer.subscription_document.submission_method != "upload"
      );
    },
    ghosted_mail() {
      return this.vdata.offer.subscription_document.submission_method != "mail";
    },
    ghosted_email() {
      return (
        this.vdata.offer.subscription_document.submission_method != "email"
      );
    },
    show_docusign_controls() {
      return !(
        ["received", "approved"].includes(
          this.vdata.offer.subscription_document.status
        ) &&
        this.vdata.offer.subscription_document.submission_method != "docusign"
      );
    },
    show_offline_controls() {
      return !(
        ["received", "approved"].includes(
          this.vdata.offer.subscription_document.status
        ) &&
        this.vdata.offer.subscription_document.submission_method == "docusign"
      );
    },
    subdoc_download_url() {
      return `/investor/deals/${this.vdata.deal.id}/offers/${this.vdata.offer.id}/subdocs`;
    },
    tab_class() {
      return {
        "submit-offer__tab--disabled":
          this.vdata.ui_state.tab_states.subdocs.disabled,
      };
    },
    tab_error() {
      return [
        "created",
        "rejected_retrying",
        "rejected",
        "withdrawn",
        "in_progress",
        "declined",
      ].includes(this.vdata.offer.subscription_document.status);
    },
  },
  watch: {
    "vdata.offer.subscription_document.submission_method"() {
      this.vdata.controller.select_subdoc_method(
        this.vdata.offer.subscription_document.submission_method
      );
    },
  },
  methods: {
    submit() {
      if (
        this.vdata.accreditation &&
        ["in_review", "in_transit", "received", "approved"].includes(
          this.vdata.accreditation.status
        )
      ) {
        this.vdata.controller.goto_step("funding");
      } else {
        this.vdata.controller.next_step();
      }
    },
    back() {
      this.vdata.controller.goto_step("entity");
    },
    delayed_push() {
      this.vdata.offer.subscription_document.submission_method = "upload";
      setTimeout(this.vdata.controller.push_and_fetch_data, 3000);
      true;
    },
    toggle_tab(method) {
      if (this.ui_state.visible_method == method) {
        this.ui_state.visible_method = "";
        return true;
      }
      this.ui_state.visible_method = method;
      return true;
    },
    upload_selected() {
      this.vdata.offer.subscription_document.submission_method = "upload";
    },
  },
};
</script>
