/*
 *
 * @file investment-detail
 *
 */

import { createApp } from "vue";
import { configureVueAppDefaults } from 'shared/vue-configured';
const App = require('./investment-detail.vue').default;

class InvestmentDetail {

  constructor(app) {
    this.init = this.init.bind(this);
    this.app = app;
    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    if (!$("[data-vue-target=investment-detail]").length) {
      return;
    }
    const base_data = window.investment_data;
    base_data.ui_state = this.default_ui_state();
    base_data.controller = this;
    this.vm = createApp(App, { vdata: base_data });
    configureVueAppDefaults(this.vm);
    this.vm.mount("[data-vue-target=investment-detail]");

    // this.vm = new Vue({
    //   el: '[data-vue-target=investment-detail]',
    //   components: { App },
    //   data() {
    //     return {vdata: base_data};
    //   },
    //   template: '<App v-bind:vdata="vdata"/>'
    // });
    window._vm = this.vm;
  }

  default_ui_state() {}
}


export default InvestmentDetail;
