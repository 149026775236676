<template>
  <div
    v-if="vdata.ui_state.current_tab == 'accred'"
    class="submit-offer__tab submit-offer__tab--accreditation"
    data-cy="submit-offer__tab"
    :class="tab_class"
  >
    <div class="panel__section">
      <StepMessage :message="vdata.ui_state.tab_states.accred.message" />
      <div v-if="accreditation_status == 'approved'" class="callout callout--submit-flow">
        <div class="callout__text">
          <h2 class="callout__heading">
            Your Accreditation Verification is approved.
          </h2>
          Click 'Next' to continue.
        </div>
      </div>
      <div v-if="accreditation_status == 'received'" class="callout callout--submit-flow">
        <div class="callout__text">
          <h2 class="callout__heading">
            You have successfully uploaded your Verification Letter
          </h2>
          Thank you for your submission. We've received your document(s) and will contact you if anything else is needed.
          For your records, you can <a :href="vdata.accreditation.offer_document.uri" download>download a copy</a> of your signed documents.
        </div>
      </div>
      <div v-if="vdata.offer.subscription_document.status == 'received'" class="callout callout--submit-flow">
        <div class="callout__text">
          <h2 class="callout__heading">
            You have successfully uploaded your Subscription Document.
          </h2>
          Thank you for your submission. We've received your document(s) and will contact you if anything else is needed.
          For your records, you can <a :href="vdata.offer.subscription_document.offer_document.uri" download>download</a> a copy of your signed documents.
        </div>
      </div>
      <div class="row align-justify small-mb-30">
        <div class="column small-12" :class="hide_cta ? 'large-9' : 'large-8'">
          <h2 class="submit-offer__heading" data-cy="submit-offer__heading">
            Investor Verification
          </h2>
          <p>
            Please verify that you are an accredited investor. This is done by providing a letter, verifying that you are an accredited investor per SEC guidelines.
          </p>
          <p>
            There are two ways to do this. A fast and easy method is to get accredited online by selecting “Online Verification.” ArborCrowd covers the cost of online accreditation. Or you can choose “Manual Verification” to download the form, get it completed by an attorney, CPA or an investment advisor, and then upload, email or mail it back.
          </p>
        </div>
        <div class="column align-self-bottom show-for-large">
          <CTA :class="{hide: hide_cta}" />
        </div>
      </div>

      <div v-if="accreditation_status != 'received' && accreditation_status != 'approved'" class="submit-offer__subtabs tabs">
        <h3 class="submit-offer__subheading small-mb-30">
          Select a method to complete your investor verification:
        </h3>

        <div class="row">
          <div v-if="show_vi_controls" class="column tabs__title">
            <div class="tabs__title-content first" data-cy="submit-offer__tab-electronic">
              <span class="submit-offer__subheading small-mb-14 display-block">Online Verification (Preferred)</span>
              <a
                :href="vdata.ui_state.verify_investor_url"
                type="button"
                class="button button--primary button--uppercase button--large"
                data-cy="submit-offer__verify-investor-button"
                data-turbolinks="false"
                @click="toggle_tab('online')"
              >
                Get Started
              </a>
            </div>
          </div>
          <div class="column small-1 align-middle text-center small-pl-0 small-pr-0">
            <span class="submit-offer__subheading display-block small-mt-30">OR</span>
          </div>
          <div v-if="show_offline_controls" class="column tabs__title" :class="{ 'tabs__title--active': ui_state.visible_method == 'offline' }">
            <div class="tabs__title-content" data-cy="submit-offer__tab-manual">
              <span class="submit-offer__subheading small-mb-14 display-block">Manual Verification</span> <br class="hide-for-medium">
              <button
                type="button"
                class="button button--primary button--uppercase button--large"
                :class="{ 'button--disabled': ui_state.visible_method == 'offline' }"
                data-cy="submit-offer__button"
                @click="toggle_tab('offline')"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
        <div v-if="ui_state.visible_method == 'offline' && accreditation_type != 'verify_investor'" class="tabs__tab">
          <template v-if="accreditation_status != 'received'">
            <div class="row collapse">
              <div class="column shrink">
                <h6 class="tabs__tab-heading small-pr-40">
                  Step 1
                </h6>
              </div>
              <div class="column">
                <p class="tabs__tab-text tabs__tab-text--large">
                  Download Verification Form
                </p>
                <a
                  id="accred_download"
                  :href="accred_download_url"
                  download
                  target="_blank"
                  class="button button--primary button--tab text-uppercase"
                  data-turbolinks="false"
                  data-cy="submit-offer__button"
                  @click="delayed_push"
                >Download</a>
              </div>
            </div>
            <hr class="tabs__tab-hr">
          </template>

          <div v-if="accreditation_status" class="row collapse">
            <div class="column shrink">
              <h6 class="tabs__tab-heading small-pr-40">
                Step 2
              </h6>
            </div>
            <div class="column">
              <p class="tabs__tab-text tabs__tab-text--large">
                Get the form completed by an attorney, CPA or investment advisor.
              </p>
            </div>
          </div>

          <hr v-if="accreditation_status" class="tabs__tab-hr">

          <div v-if="accreditation_status" class="row collapse">
            <div class="column shrink">
              <h6 class="tabs__tab-heading small-pr-40">
                Step 3
              </h6>
            </div>
            <div class="column">
              <p class="tabs__tab-text tabs__tab-text--large">
                Return signed Verification Letter:
              </p>

              <div v-if="vdata.accreditation">
                <p class="tabs__tab-text small-mb-20">
                  Upload signed Verification Letter (preferred)
                </p>
                <div v-if="accreditation_status != 'received'">
                  <Uploader
                    :vdata="{
                      choose_label: 'Upload',
                      upload_label: 'Submit Document',
                      button_class: ghosted_upload ? 'button button--primary button--tab text-uppercase' : '',
                      endpoint: vdata.controller.accred_control_url(),
                      onsuccess: success_upload,
                      mime_types: ['application/pdf']
                    }"
                    @addedfile="upload_selected"
                  />
                </div>
                <hr class="tabs__tab-hr tabs__tab-hr--dashed small-mt-30 small-mb-15">

                <p class="tabs__tab-text small-mb-0" :class="{'text-ghosted': ghosted_email}">
                  Email your signed Verification Letter to:
                </p>

                <p class="tabs__tab-text" :class="{'text-ghosted': ghosted_email}">
                  <a href="mailto:subscriptions@arborcrowd.com" class="text-orange">
                    subscriptions@arborcrowd.com
                  </a>
                </p>

                <RadioButton
                  v-model="vdata.accreditation.accreditation_type"
                  data-cy="accreditation__email-verification"
                  label="SENT BY EMAIL"
                  name="accred_return_method"
                  value="email"
                  :expanded="false"
                />

                <hr class="tabs__tab-hr tabs__tab-hr--dashed small-mt-30 small-mb-15">

                <p class="tabs__tab-text" :class="{'text-ghosted': ghosted_mail}">
                  Mail your signed Verification Letter to:
                </p>

                <p class="tabs__tab-text text-smaller" :class="{'text-ghosted': ghosted_mail}">
                  ArborCrowd Investor Relations<br>
                  333 Earle Ovington Blvd., Suite 900<br>
                  Uniondale, NY 11553
                </p>

                <RadioButton
                  v-model="vdata.accreditation.accreditation_type"
                  name="accred_return_method"
                  value="mail"
                  data-cy="accreditation__mail-verification"
                  label="SENT BY MAIL"
                  :expanded="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="submit-offer__actions small-mt-8">
        <div class="row">
          <div class="small-6 column text-left">
            <button
              v-if="!vdata.ui_state.tab_states.subdocs.disabled && !tab_error"
              type="button"
              class="submit-offer__actions-button button button--hollow"
              @click="back"
            >
              Back
            </button>
          </div>
          <div class="small-6 column text-right">
            <button
              type="button"
              class="submit-offer__actions-button button"
              data-cy="submit-offer__submit-button"
              :disabled="tab_error"
              @click="submit"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <CTA class="hide" />
    </div>
  </div>
</template>

<script>
import { default as CTA } from './cta.vue';
import { default as RadioButton } from './radio-button.vue';
import { default as StepMessage } from './step-message.vue';
import { default as Uploader } from 'shared/upload-file.vue';

export default
  ({
    name: "AccredTab",
    components: {
      CTA,
      RadioButton,
      StepMessage,
      Uploader
    },
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    data: function() {
      let visible_method;
      switch(this.accreditation_type()){
        case "unknown":
          visible_method = 'online';
        break;
        case "verify_investor":
          visible_method = 'online';
        break;
        case "upload":
        case "email":
        case "mail":
        default:
          visible_method = 'offline';
        break;
      }
      return {
        ui_state: {
          visible_method: visible_method
        }
      }
    },
    computed: {
      accred_download_url() {
        return `/investor/deals/${this.vdata.deal.id}/accreditation_template?entity_id=${this.vdata.entity.id}`;
      },
      accreditation_status() {
        return (this.vdata.accreditation ? this.vdata.accreditation.status : undefined);
      },
      ghosted_upload() {
        return(this.accreditation_type() != 'upload');
      },
      ghosted_mail() {
        return(this.accreditation_type() != 'mail');
      },
      ghosted_email() {
        return(this.accreditation_type() != 'email');
      },
      hide_cta() {
        return( ['received', 'in_transit', 'other_sigs'].includes(this.vdata.offer.subscription_document.status) );
      },
      tab_class(){
        return {
          "submit-offer__tab--disabled": this.vdata.ui_state.tab_states.accred.disabled,
          "submit-offer__tab--error": this.tab_error
        }
      },
      tab_error(){
        const accred_status = this.vdata.accreditation ? this.vdata.accreditation.status : undefined;
        const accred_bad = !['approved', 'received', 'in_transit', 'in_review', 'in_progress'].includes(accred_status) || ( accred_status === 'in_progress' && this.vdata.accreditation.accreditation_type === 'upload' );
        const subdoc_bad = ['created', 'rejected_retrying', 'rejected', 'in_progress', 'withdrawn'].includes(this.vdata.offer.subscription_document.status);
        return(accred_bad || subdoc_bad);
      },
      show_vi_controls() {
        return (
          ! (['received', 'approved'].includes(this.accreditation_status)
            && this.accreditation_type() != "verify_investor"
          )
        );
      },
      show_offline_controls() {
        return (
          ! (['received', 'approved'].includes(this.accreditation_status)
            && this.accreditation_type() == "verify_investor"
          ));
      }
    },
    watch: {
      'vdata.accreditation.accreditation_type'(){
         this.vdata.controller.select_accred_method(this.vdata.accreditation.accreditation_type)
      }
    },
    methods: {
      accreditation_type() {
        return (this.vdata.accreditation ? this.vdata.accreditation.accreditation_type : "unknown");
      },
      submit(){
        this.vdata.controller.next_step();
      },
      back(){
        this.vdata.controller.goto_step('subdocs');
      },
      success_upload() {
        this.vdata.controller.push_and_fetch_data()
        .then(() => {
          if (this.vdata.offer.subscription_document.status === "received" &&
              this.vdata.accreditation.accreditation_type === "upload" &&
              this.vdata.accreditation.status === "received") {
                this.vdata.controller.goto_step('funding');
          }
        });
      },
      delayed_push() {
        setTimeout(this.vdata.controller.push_and_fetch_data, 3000);
        return true;
      },
      toggle_tab(method) {
        if (this.ui_state.visible_method == method) {
          this.ui_state.visible_method = ''
          return;
        }
        this.ui_state.visible_method = method;
      },
      upload_selected(){
        this.vdata.accreditation.accreditation_type = 'upload';
      }
    }
  });
</script>
