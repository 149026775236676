/*
 *
 * @file subnav
 *
 */

class Subnav {

  constructor(app) {

    this.init = this.init.bind(this);
    this.sticky = this.sticky.bind(this);
    this.app = app;

    //#
    // DOM Elements
    // @type {string}
    //#
    this.el = {
      body:   'body',
      subnav: '[data-subnav]'
    };

    //#
    // DOM Element Modifiers
    // @type {string}
    //#
    this.el_modifiers = {
      body: {
        subnav_is_fixed: 'body--subnav-is-fixed'
      },
      subnav: {
        fixed: 'subnav--fixed'
      }
    };

    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    if (!$(this.el.subnav).length) {
      return;
    }

    this.sticky();
  }

  sticky() {

    let did_scroll = false;
    const offset = 198;

    const has_scrolled = () => {

      const st = $(document).scrollTop();

      if ((st > offset)) {
        $(this.el.subnav).addClass(this.el_modifiers.subnav.fixed);
        $(this.el.body).addClass(this.el_modifiers.body.subnav_is_fixed);
      } else {
        $(this.el.subnav).removeClass(this.el_modifiers.subnav.fixed);
        $(this.el.body).removeClass(this.el_modifiers.body.subnav_is_fixed);
      }
    };

    $(window).scroll( ()=> did_scroll = true);

    setInterval((function() {
      if (did_scroll) {
        has_scrolled();
        return did_scroll = false;
      }
    }), 250);
  }
}

export default Subnav;
