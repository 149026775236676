<template>
  <div
    v-if="vdata.ui_state.current_tab == 'entity'"
    class="submit-offer__tab submit-offer__tab--entity"
    data-cy="submit-offer__tab"
    :class="[tab_class, {'submit-offer__tab--error': tab_error}]"
  >
    <div class="panel__section">
      <StepMessage :message="vdata.ui_state.tab_states.entity.message" />
      <div class="row align-justify">
        <div class="column small-12 large-8">
          <h2 class="submit-offer__heading" data-cy="submit-offer__heading">
            Investor &amp; Entity Information
          </h2>
          <p>
            Please select your investment entity type and fill in investor information below.
          </p>
        </div>
        <div class="column align-self-bottom show-for-large">
          <CTA :items="{ learn: true, relations: false }" />
        </div>
      </div>

      <fieldset class="fieldset">
        <legend class="fieldset__legend">
          Entity Information
        </legend>
        <div class="fieldset__content">
          <div class="row">
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="investment_entity">
                Select Investment Entity*
              </label>
              <select
                id="entity_type"
                class="fieldset__select"
                :value="entity_select_value"
                data-cy="submit-offer__entity-type"
                required
                @change="vdata.controller.update_entity"
              >
                <option disabled :value="null">
                  Select
                </option>
                <option v-for="option in available_entities" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div v-if="show_member_pulldown" class="column smalll-12 medium-4">
              <label class="fieldset__input-label" for="signatories">
                Number of signatories*
              </label>
              <select
                id="requested_signatory_slots"
                v-model="vdata.ui_state.requested_signatory_slots"
                class="fieldset__select"
                data-cy="submit-offer__number-of-signatories"
                :disabled="vdata.entity.locked"
                @change="vdata.controller.reconcile_requested_slots"
              >
                <option v-for="option in available_signatory_slots" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div v-show="show_entity_name" class="column small-12 medium-4">
              <label class="fieldset__input-label" for="entity_name">
                Legal Entity Name*
              </label>
              <input
                id="legal_entity_name"
                v-model="vdata.entity.legal_entity_name"
                class="fieldset__input"
                data-cy="submit-offer__legal-entity-name"
                required
                type="text"
                :disabled="vdata.entity.locked"
              >
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="fieldset">
        <legend class="fieldset__legend">
          Investor Information ({{ vdata.user.first_name }} {{ vdata.user.last_name }})
        </legend>
        <div class="fieldset__content">
          <div class="row">
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="investor_first_name">
                First name*
              </label>
              <input
                id="first_name"
                v-model="vdata.user.first_name"
                class="fieldset__input"
                data-cy="submit-offer__first-name"
                required
                disabled
                type="text"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="investor_last_name">
                Last name*
              </label>
              <input
                id="last_name"
                v-model="vdata.user.last_name"
                class="fieldset__input"
                data-cy="submit-offer__last-name"
                required
                disabled
                type="text"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="primary_phone">
                Primary Phone*
              </label>
              <input
                id="primary_phone"
                v-model="vdata.user.primary_phone"
                class="fieldset__input"
                data-cy="submit-offer__primary-phone"
                required
                type="text"
              >
              <span class="form-error">Number with area code required</span>
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="address">
                Residential Address*
              </label>
              <input
                id="address"
                v-model="vdata.user.address"
                class="fieldset__input"
                data-cy="submit-offer__address"
                required
                type="text"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="city">
                City*
              </label>
              <input
                id="city"
                v-model="vdata.user.city"
                class="fieldset__input"
                data-cy="submit-offer__city"
                required
                type="text"
              >
            </div>
            <div class="column small-12 medium-4">
              <div class="row">
                <div class="column small-6">
                  <label class="fieldset__input-label" for="state_or_region">
                    State / Province*
                  </label>
                  <select
                    id="state_or_region"
                    v-model="vdata.user.state_or_region"
                    class="fieldset__select"
                    data-cy="submit-offer__state"
                  >
                    <option :value="null" />
                    <option v-for="(state, abbrev) of states" :key="abbrev" :value="abbrev">
                      {{ state }}
                    </option>
                  </select>
                </div>
                <div class="column small-6">
                  <label class="fieldset__input-label" for="postal_code">
                    Postal Code*
                  </label>
                  <input
                    id="postal_code"
                    v-model="vdata.user.postal_code"
                    class="fieldset__input"
                    required
                    type="text"
                    data-cy="submit-offer__postal-code"
                  >
                </div>
              </div>
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="email">
                Email Address*
              </label>
              <input
                id="email"
                v-model="vdata.user.email"
                class="fieldset__input"
                data-cy="submit-offer__email"
                required
                disabled
                type="text"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="investor_country">
                Country*
              </label>
              <select
                id="country"
                v-model="vdata.user.country"
                class="fieldset__select"
                data-cy="submit-offer__country"
              >
                <option :value="null" />
                <option v-for="country in countries" :key="country" :value="country">
                  {{ country }}
                </option>
              </select>
            </div>
            <div class="column small-12 show-for-medium">
              <hr class="fieldset__hr">
            </div>
            <div v-if="!vdata.entity.locked || vdata.ui_state.mailing_address_different" class="column medium-12">
              <Checkbox
                v-model="vdata.ui_state.mailing_address_different"
                :disabled="vdata.entity.locked"
                size="small"
                label="Check if Mailing Address is different than Residential Address"
                data-cy="submit-offer__mailing-address-different"
              />
            </div>
            <template v-if="vdata.ui_state.mailing_address_different">
              <div class="column small-12 medium-4">
                <label class="fieldset__input-label" for="investor_mailing_address">
                  Mailing Address*
                </label>
                <input
                  id="investor_mailing_address"
                  v-model="vdata.entity.address"
                  class="fieldset__input"
                  data-cy="submit-offer__mailing-address"
                  :disabled="vdata.entity.locked"
                  required
                  type="text"
                >
              </div>
              <div class="column small-12 medium-4">
                <label class="fieldset__input-label" for="investor_mailing_city">
                  City*
                </label>
                <input
                  id="investor_mailing_city"
                  v-model="vdata.entity.city"
                  class="fieldset__input"
                  data-cy="submit-offer__mailing-city"
                  :disabled="vdata.entity.locked"
                  required
                  type="text"
                >
              </div>
              <div class="column small-12 medium-4">
                <div class="row">
                  <div class="column medium-6">
                    <label class="fieldset__input-label" for="investor_mailing_state_or_region">
                      State / Province*
                    </label>
                    <select
                      id="investor_mailing_state_or_region"
                      v-model="vdata.entity.state_or_region"
                      :disabled="vdata.entity.locked"
                      class="fieldset__select"
                      data-cy="submit-offer__mailing-state"
                      required
                    >
                      <option :value="null" />
                      <option v-for="(state, abbrev) of states" :key="abbrev" :value="abbrev">
                        {{ state }}
                      </option>
                    </select>
                  </div>
                  <div class="column medium-6">
                    <label class="fieldset__input-label" for="investor_mailing_postal_code">
                      Postal Code*
                    </label>
                    <input
                      id="investor_mailing_postal_code"
                      v-model="vdata.entity.postal_code"
                      :disabled="vdata.entity.locked"
                      class="fieldset__input"
                      data-cy="submit-offer__mailing-postal-code"
                      required
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </fieldset>

      <fieldset v-if="signatories.length > 0" class="fieldset">
        <legend class="fieldset__legend">
          Entity Signatories
        </legend>
        <div class="fieldset__content">
          <MemberBlock
            v-for="(signatory, index) in signatories"
            :key="index"
            :index="index"
            :title="signatory_title(index)"
            :disabled="index == 0 || vdata.entity.locked"
            :member="signatory"
            :vdata="vdata"
            data-cy="member"
          />
        </div>
      </fieldset>
    </div>
    <div class="panel__section">
      <h2 class="submit-offer__heading">
        Distributions
      </h2>

      <p>
        Please enter your distribution account information.
      </p>

      <fieldset class="fieldset">
        <legend class="fieldset__legend">
          Entity Banking Information
        </legend>
        <div class="fieldset__content">
          <div class="row">
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="preferred_distribution_method">
                Preferred Distribution Payment Method<template v-if="vdata.entity.entity_type != 'sdira'">*</template>
              </label>
              <select
                id="preferred_distribution_method"
                v-model="vdata.offer.preferred_distribution_method"
                class="fieldset__select"
                :required="vdata.entity.entity_type != 'sdira'"
                data-cy="submit-offer__distribution-method"
              >
                <option value="unknown">
                  -- Select --
                </option>
                <option value="ach">
                  ACH
                </option>
                <option v-if="vdata.entity.entity_type === 'sdira'" value="wire">
                  Wire
                </option>
              </select>
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="financial_institution">
                Financial Institution<template v-if="vdata.entity.entity_type != 'sdira'">*</template>
              </label>
              <input
                id="financial_institution"
                v-model="vdata.offer.financial_institution"
                class="fieldset__input"
                type="text"
                :required="vdata.entity.entity_type != 'sdira'"
                data-cy="submit-offer__financial-institution"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="account_name">
                Account Name<template v-if="vdata.entity.entity_type != 'sdira'">*</template>
              </label>
              <input
                id="account_name"
                v-model="vdata.offer.account_name"
                class="fieldset__input"
                type="text"
                :required="vdata.entity.entity_type != 'sdira'"
                placeholder="Bank One Checking"
                data-cy="submit-offer__account-name"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="account_number">
                Account Number<template v-if="vdata.entity.entity_type != 'sdira'">*</template>
              </label>
              <input
                id="account_number"
                v-model="vdata.offer.account_number"
                class="fieldset__input"
                type="text"
                :required="vdata.entity.entity_type != 'sdira'"
                placeholder="1234 000 2938"
                data-cy="submit-offer__account-number"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="routing_ach_number">
                ACH Routing Number<template v-if="vdata.entity.entity_type != 'sdira'">*</template>
              </label>
              <input
                id="routing_ach_number"
                v-model="vdata.offer.routing_ach_number"
                class="fieldset__input"
                type="text"
                maxlength="9"
                :required="vdata.entity.entity_type != 'sdira'"
                placeholder="123456789"
                data-cy="submit-offer__ach-routing-number"
              >
              <span class="form-error">9 digits required</span>
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="financial_institution_address">
                Financial Institution Address
              </label>
              <input
                id="financial_institution_address"
                v-model="vdata.offer.financial_institution_address"
                class="fieldset__input"
                type="text"
                placeholder=""
                data-cy="entity__financial-institution-address"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="financial_institution_city">
                Financial Institution City
              </label>
              <input
                id="financial_institution_city"
                v-model="vdata.offer.financial_institution_city"
                class="fieldset__input"
                type="text"
                placeholder=""
                data-cy="entity__financial-institution-city"
              >
            </div>
            <div class="column small-12 medium-4">
              <label class="fieldset__input-label" for="financial_institution_state">
                Financial Institution State
              </label>
              <select
                id="financial_institution_state"
                v-model="vdata.offer.financial_institution_state"
                class="fieldset__select"
                data-cy="entity__financial-institution-state"
              >
                <option :value="null" />
                <option v-for="(state, abbrev) of states" :key="abbrev" :value="abbrev">
                  {{ state }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="submit-offer__actions small-pt-8 large-pt-0">
        <div class="row">
          <div class="small-6 column text-left">
            <button type="button" class="submit-offer__actions-button button button--hollow" @click="back">
              Back
            </button>
          </div>
          <div class="small-6 column text-right">
            <button
              type="button"
              class="submit-offer__actions-button button"
              data-cy="submit-offer__submit-button"
              :disabled="tab_error"
              @click="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as Checkbox } from './checkbox.vue';
import { default as CTA } from './cta.vue';
import { default as MemberBlock } from './member_block.vue';
import { default as States } from 'shared/states';
import { default as Countries } from 'shared/countries';
import { default as StepMessage } from './step-message.vue'

export default
  ({
    name: "EntityTab",
    components: {
      Checkbox,
      CTA,
      MemberBlock,
      StepMessage
    },
    props: {
      vdata: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        available_signatory_slots: [1, 2, 3, 4],
        states: States,
        countries: Countries,
        signatories: this.who_are_signatories(this.$props.vdata.entity.members)
      };
    },
    computed: {
      // We only allow a single entity of type 'individual' for a user, so value for the 'individual'
      // option in the pulldown will either be new_individual or the ID of the existing one.
      available_entities() {
        let e;
        let ind_id = false;
        const entity_list = [];
        for (e of this.vdata.user_entities) {
          if (e.entity_type === 'individual') {
            ind_id = e.id;
          } else if (!this.vdata.user_in_progress_entities.includes(e.id) || this.vdata.entity.id === e.id) {
            entity_list.push({ value: e.id, label: e.legal_entity_name });
          }
        }
        for (e of this.vdata.ui_defs.entity_types) {
          if (e.value === 'individual') {
            if (ind_id) {
              if (!this.vdata.user_in_progress_entities.includes(ind_id) || (this.vdata.entity.id && this.vdata.entity.id == ind_id)) {
                entity_list.push({ value: ind_id, label: "Individual" });
              }
            } else {
              entity_list.push({ value: "new_individual", label: "Individual" });
            }
          } else {
            entity_list.push({ value: `new_${e.value}`, label: e.label });
          }
        }
        return entity_list;
      },
      entity_select_value() {
        if (this.vdata.entity.id != null) {
          return this.vdata.entity.id;
        } else if (this.vdata.entity.entity_type != null) {
          return `new_${this.vdata.entity.entity_type}`;
        } else {
          return null;
        }
      },
      show_member_pulldown() {
        return ![null, 'individual', 'joint', 'sdira'].includes(this.vdata.entity.entity_type);
      },
      show_entity_name() {
        return ![null, 'individual', 'joint'].includes(this.vdata.entity.entity_type);
      },
      tab_class(){
        return { "submit-offer__tab--disabled": this.vdata.ui_state.tab_states.entity.disabled }
      },
      tab_error(){
        let error = null
        let field, residential_fields, mailing_fields, sig_fields, mem, $tab;
        $tab = $(".submit-offer__tab--entity");
        $tab.find('input, select').removeClass('error');

        residential_fields = ['primary_phone', 'address', 'city', 'state_or_region', 'postal_code', 'country'];
        mailing_fields = ['address', 'city', 'state_or_region', 'postal_code'];
        sig_fields = ['first_name', 'last_name', 'user'];

        if (!this.vdata.entity.entity_type) {
          $tab.find('#entity_type').addClass('error');
          error = true
        }
        if (!['individual', 'joint'].includes(this.vdata.entity.entity_type)) {
          if (!this.vdata.entity.legal_entity_name) {
            $tab.find('#legal_entity_type').addClass('error');
            error = true
          }
        }
        for (field of residential_fields) {
          if (!this.vdata.user[field]) {
            $tab.find('#' + field).addClass('error');
            error = true
          }
        }
        if (this.vdata.user.primary_phone) {
          if (this.vdata.user.primary_phone.replace(/[^0-9]/g,'').length < 10) {
            $tab.find('#primary_phone').addClass('error');
            error = true;
          }
        }
        if (this.vdata.ui_state.mailing_address_different) {
          for (field of mailing_fields) {
            if (!this.vdata.entity[field]) {
              $tab.find('#investor_mailing_' + field).addClass('error');
              error = true
            }
          }
        }
        for (let i in this.vdata.entity.members) {
          mem = this.vdata.entity.members[i];
          sig_fields.forEach( (field)=> {
              if(!mem[field]){
                $tab.find(`#signatory_${i}_${field}`).addClass('error');
                error = true;
              }
            }
          )
        }
        for (let i in this.vdata.entity.members) {
          mem = this.vdata.entity.members[i];
          if (!mem.user.match( Foundation.Abide.defaults.patterns.email )) {
            $tab.find(`#signatory_${i}_user`).addClass('error');
            error = true
          }
        }

        for (let i in this.vdata.entity.members) {
          mem = this.vdata.entity.members[i];

          if (mem.user && this.vdata.entity.members.filter(member => member.user === mem.user).length > 1 && i > 0) {
            $tab.find(`#signatory_${i}_user`)
                .addClass('error')
                .next()
                .text('Cannot match other signatory email address');

            error = true;
          } else {
            $tab.find(`#signatory_${i}_user`)
                .next()
                .text('');
          }
        }

        if(this.vdata.offer.preferred_distribution_method === "unknown" && this.vdata.entity.entity_type != "sdira"){
          $tab.find('#preferred_distribution_method').addClass('error');
          error = true
        }

        // validate presence for all entity types except SDIRA
        if(!this.vdata.offer.routing_ach_number && this.vdata.entity.entity_type != 'sdira'){
          $tab.find('#routing_ach_number').addClass('error');
          error = true;
        }

        // validate 9-digits if present
        if (this.vdata.offer.routing_ach_number && !this.vdata.offer.routing_ach_number.match(/^\d{9}$/)){
          $tab.find('#routing_ach_number').addClass('error');
          error = true;
        }

        for (field of [
          'financial_institution',
          'account_name',
          'account_number'
        ]) {
          if (!this.vdata.offer[field] && this.vdata.entity.entity_type != "sdira") {
            $tab.find('#' + field).addClass('error');
            error = true;
          }
        }
        return (error ? true : null)
      }
    },
    watch: {
      "vdata.ui_state.mailing_address_different"(newval, oldval) {
        if(oldval && !this.vdata.entity.locked){
          this.vdata.entity.address = "";
          this.vdata.entity.city = "";
          this.vdata.entity.state_or_region = "";
          this.vdata.entity.postal_code = "";
        }
      },
      "vdata.entity.members": {
        handler(newval){
          this.signatories = this.who_are_signatories(newval);
        },
        deep: true
      }
    },
    methods: {
      submit(){
        this.vdata.controller.next_step();
      },
      back(){
        this.vdata.controller.goto_step('amount');
      },
      signatory_title(index){
        if (this.vdata.entity.entity_type === "sdira") {
          if (index === 0) {
            return "Signatory";
          } else {
            return "Custodian";
          }
        } else if (this.vdata.entity.entity_type === "individual") {
          return "Signatory";
        } else {
          return `Signatory ${index + 1}`;
        }
      },
      who_are_signatories(members) {
        return(members.filter((sig) => ['owner','signatory'].includes(sig.access_type)));
      },
    }
  });
</script>
