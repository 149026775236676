/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*
 *
 * @file investor_control.coffee
 * @desc This controller manages and registers page components and delegates
 * turbolinks events to them.
 *
 * It's also a one-stop shop for passing configurations to submodules (things like
 * class names that might change, etc)
 *
 */




/*
 *
 * Set up Foundation dependencies
 *
 */

// import WhatInput from 'what-input'
window.$ = require('jquery');
import { Foundation } from 'foundation-sites/js/foundation.core.js';
import Turbolinks from 'turbolinks';

// Foundation Utils
import { Box } from 'foundation-sites/js/foundation.util.box.js';
import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader.js';
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard.js';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery.js';
import { Triggers } from 'foundation-sites/js/foundation.util.triggers.js';
import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll.js';
import { Nest } from 'foundation-sites/js/foundation.util.nest.js';
import { Touch } from 'foundation-sites/js/foundation.util.touch.js';

// Foundation Plugins core
import { Abide } from 'foundation-sites/js/foundation.abide.js';
import { Accordion } from 'foundation-sites/js/foundation.accordion.js';
// `import { Dropdown } from 'foundation-sites/js/foundation.dropdown.js'`
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu.js';
import { Equalizer } from 'foundation-sites/js/foundation.equalizer.js'
// `import { Interchange } from 'foundation-sites/js/foundation.interchange.js'`
// `import { Magellan } from 'foundation-sites/js/foundation.magellan.js'`
import { Reveal } from 'foundation-sites/js/foundation.reveal.js';


// This object is an argument to our controller, below.
const foundation_plugins = {
  Abide,
  Accordion,
  // Dropdown: Dropdown
  'dropdown-menu': DropdownMenu,
  Equalizer: Equalizer,
  // Interchange: Interchange
  // Magellan: Magellan
  Reveal
};

window.Foundation = Foundation;
Foundation.plugin(MediaQuery, 'MediaQuery');
Foundation.plugin(Triggers, 'Triggers');
Foundation.plugin(Box, 'Box');
Foundation.plugin(onImagesLoaded, 'onImagesLoaded');
Foundation.plugin(Keyboard, 'Keyboard');
Foundation.plugin(SmoothScroll, 'SmoothScroll');
Foundation.plugin(Nest, 'Nest');
Foundation.plugin(Touch, 'Touch');
Foundation.plugin(Abide, 'Abide');
Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin Interchange, 'Interchange'
Foundation.plugin(Accordion, 'Accordion');
// Foundation.plugin Dropdown, 'Dropdown'
Foundation.plugin(DropdownMenu, 'DropdownMenu');
// Foundation.plugin Magellan, 'Magellan'
Foundation.plugin(Reveal, 'Reveal');


import UserPassword from 'modules/shared/user-password.js';
Foundation.Abide.defaults.validators['user_password'] = UserPassword();
import TenDigitPhone from 'modules/shared/10-digit-phone.js';
Foundation.Abide.defaults.validators['10_digit_phone'] = TenDigitPhone();

// Validate text inputs on any value change
Foundation.Abide.defaults.liveValidate = true;

// register foundation with JQuery
Foundation.addToJquery($);

/*
 *
 * End Foundation Setup
 *
 */




// Here's the master list of our bespoke components and their config settings.
import ActivityLog from 'modules/investor/activity-log.js';
import AjaxModal from 'modules/shared/ajax-modal.js';
import Analytics from 'modules/investor/analytics.js';
import Deal from 'modules/investor/deal.js';
import DocumentList from 'modules/investor/document-list.js';
import DocusignEmbed from 'modules/investor/docusign-embed.js';
import EventBus from 'modules/shared/event-bus.js';
import Header from 'modules/investor/header.js';
// import Prototypes from 'modules/investor/prototypes.js';
import InvestmentDetail from 'modules/investor/investment-detail.js';
import InvestmentSummary from 'modules/investor/investment-summary.js';
import PasswordVisibility from 'modules/investor/password-visibility.js';
import Registration from 'modules/investor/registration.js'; // also contains some sign-in relevant code
import SubmitOffer from 'modules/investor/submit-offer.js';
import Subnav from 'modules/investor/subnav.js';
import TabularData from 'modules/admin/tabular-data.js';
import UploadFile from 'modules/shared/upload-file.js';

const components = {
  ActivityLog: {
    component: ActivityLog,
    config: {}
  },
  AjaxModal: {
    component: AjaxModal,
    config: {}
  },
  Analytics: {
    component: Analytics,
    config: {}
  },
  Deal: {
    component: Deal,
    config: {}
  },
  DocumentList: {
    component: DocumentList,
    config: {}
  },
  DocusignEmbed: {
    component: DocusignEmbed,
    config: {}
  },
  Header: {
    component: Header,
    config: {}
  },
  // Prototypes: {
  //   component: Prototypes
  // },
  InvestmentSummary: {
    component: InvestmentSummary
  },
  InvestmentDetail: {
    component: InvestmentDetail,
    config: {}
  },
  PasswordVisibility: {
    component: PasswordVisibility,
    config: {}
  },
  Registration: {
    component: Registration,
    config: {}
  },
  SubmitOffer: {
    component: SubmitOffer,
    config: {}
  },
  Subnav: {
    component: Subnav
  },
  TabularData: {
    component: TabularData,
    config: {}
  },
  UploadFile: {
    component: UploadFile,
    config: {}
  }
};



class InvestorControl {
  constructor(components, foundation_plugins1) {

    // An array of functions to call on turbolinks:load
    this.handle_turbolinks_load = this.handle_turbolinks_load.bind(this);
    this.handle_turbolinks_before_render = this.handle_turbolinks_before_render.bind(this);
    this.onready = this.onready.bind(this);
    this.update_foundation = this.update_foundation.bind(this);
    this.change_entity = this.change_entity.bind(this);
    this.foundation_plugins = foundation_plugins1;
    this.turbolinks_load_handlers = [];

    // Save references to instantiated components and pass them a
    // reference to this controller
    this.components = {};
    for (let n in components) {
      const c = components[n];
      this.components[n] = new c.component(this, c.config);
    }

    $(document).ready(this.onready); // just run once
    $(document).on('turbolinks:render', this.handle_turbolinks_load); // hook into the render event after first page load
    $(document).on('turbolinks:before-render', this.handle_turbolinks_before_render);
    $(document).on('turbolinks:click', this.handle_turbolinks_click);
    $(document).on('keyup', '.textcounter', this.textarea_counter);
    $(document).on('change', '[data-investment-entity]', this.change_entity);
    $(document).on('click', '[data-upload-avatar]', this.avatar_upload);
    $(document).on('ajax:success', '[data-remove-avatar]', this.avatar_remove);
    $(document).on('change', 'input[name="photo"]', this.avatar_preview);
    $(document).on('click', '[data-more-faqs]', this.display_more_faq);
    $(document).on('click', '[data-href]', this.handle_href);
  }

  // This gets picked up by the activity log component on the portfolio overview
  change_entity(e) {
    EventBus.$emit('portfolio:change_entity', e);
  }

  check_user_session() {
    if ($('body').data('uid')) {
      const url = `${window.location.origin}/ping`;
      $.ajax({
        url,
        type: "POST",
        success: payload => {
          // console.log(payload.time_left);
          if (!payload.logged_in) {
            window.location.href = payload.redirect
          }
        }
      });
    }
  }

  display_more_faq(e) {
    $(e.currentTarget).hide();
    $('[data-faq-list] li').removeClass('hide');
  }

  handle_turbolinks_before_render(e) {
    console.log('turbolinks:before-render');
    // Removes the FOUC-prevention
    e.originalEvent.data.newBody.style.removeProperty('opacity');
  }

  handle_turbolinks_click(e) {
    if ($(e.target).is("[data-open]")) { return false; } // turbolinks messes up foundation's reveal if we don't do this
    return true;
  }

  handle_turbolinks_load() {
    console.log('turbolinks:load');
    this.update_foundation();
    // Take care of double-tap problem on submenus on iOS devices
    $(document).on('touchend', '.header__nav .is-dropdown-submenu-item', (e)=> {
      const loc = $(e.target).attr('href');
      if(loc != '#' && loc != null){
        Turbolinks.visit(loc);
      }
    })
    return Array.from(this.turbolinks_load_handlers).map((f) => f.call());
  }

  handle_href(e) {
    if (e.target.tagName.toLowerCase() !== 'a') {
      e.preventDefault();
      const loc = $(e.currentTarget).data('href');
      if(loc != '#' && loc != null){
        Turbolinks.visit(loc);
      }
    }
  }

  onready() {
    // this stuff only runs once...
    // Ensure AJAX requests go out with the CSRF token
    $.ajaxSetup({
      beforeSend(xhr) {
        return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }
    });

    this.handle_turbolinks_load();
    $('body')
    .css('opacity','1')

    setInterval(this.check_user_session, 45000);

  }

  register_turbolinks_load_handler(func) {
    return this.turbolinks_load_handlers.push(func);
  }

  textarea_counter(e) {
    const $text_area = $(e.currentTarget);
    const count = $text_area.val().length;
    const limit = $text_area.attr('maxlength');
    if ((limit - count) <= 0) {
      const t = $text_area;
      return t.val(t.val().substr(0, limit));
    } else {
      return $text_area.next('.counter').find('.input-label').text(limit - count);
    }
  }

  avatar_remove(e) {
    e.preventDefault();
    $('.image--avatar').css('background-image', '');
    $('.image--avatar').addClass('image--avatar-empty');
  }

  avatar_upload(e) {
    e.preventDefault();
    $('#filesize-error').removeClass('is-visible');
    $('input[name="photo"]').click();
  }

  avatar_preview(e){
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const size = e.currentTarget.files[0].size;
      const allowed_size = $('#photo').data('max-file-size');
      if (size > allowed_size) {
        $('#filesize-error').addClass('is-visible');
        return false;
      } else {
        let reader = new FileReader();
        reader.onload = function(e){
          $(".image--avatar").css("background-image", "url("+e.target.result+")");
        }
        reader.readAsDataURL(e.currentTarget.files[0]);
      }
    }
  }

  update_foundation(destroy) {
    console.log(Foundation.MediaQuery.current);
    let name, plugin;
    if (destroy == null) { destroy = false; }
    if (destroy) {
      console.log("Destroying foundation plugins");
      for (name in this.foundation_plugins) {
        plugin = this.foundation_plugins[name];
        try {
          $(`[data-${name.toLowerCase()}]`).each(function() {
            return $(this).foundation('_destroy');
          });
        } catch {
          console.error `Could not destroy ${name} plugin`
        }
      }

      return $("[data-slick-carousel]").each(function() {
        return $(this).slick('unslick');
      });
    } else {
      console.log("Initializing foundation plugins");
      for (name in this.foundation_plugins) {
        plugin = this.foundation_plugins[name];
        $(`[data-${name.toLowerCase()}]`).each(function() {
          return new plugin($(this));
        });
      }
      $.triggersInitialized = false;
      //setting triggersInitialized to false ensures that MutationObservers will be set on new DOM elements
      Foundation.Triggers.init($);
    }
  }
}





if(window._IC == null){
  window._IC = new InvestorControl(components, foundation_plugins);
  require("@rails/ujs").start()
  require("turbolinks").start()
  require("@rails/activestorage").start()
}else{
  // If turbolinks loads a new page after a deploy, the JS tag may have a different
  // fingerprint and will get loaded, even though it's mostly the same. To prevent this,
  // if the global variable is already present, we fully reload the page.
  history.go(0);
}
