<template>
  <div class="row">
    <div class="column small-12">
      <h6 class="fieldset__heading">
        {{ title }}
      </h6>
    </div>
    <div class="column small-12 medium-4">
      <label class="fieldset__input-label" :for="`signatory_${index}_first_name`">
        First name*
      </label>
      <input
        :id="`signatory_${index}_first_name`"
        v-model="member.first_name"
        class="fieldset__input"
        data-cy="member__first-name"
        required
        :disabled="disabled"
        type="text"
      >
    </div>
    <div class="column small-12 medium-4">
      <label class="fieldset__input-label" :for="`signatory_${index}_last_name`">
        Last name*
      </label>
      <input
        :id="`signatory_${index}_last_name`"
        v-model="member.last_name"
        class="fieldset__input"
        data-cy="member__last-name"
        required
        :disabled="disabled"
        type="text"
      >
    </div>
    <div class="column small-12 medium-4">
      <label class="fieldset__input-label" :for="`signatory_${index}_user`">
        Email Address*
      </label>
      <input
        :id="`signatory_${index}_user`"
        v-model="member.user"
        class="fieldset__input"
        data-cy="member__email"
        required
        :disabled="disabled"
        type="email"
      >
      <span data-cy="member__error" class="form-error" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignatoryBlock',
  props: {
    disabled: Boolean,
    title: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    member: {
      type: Object,
      required: true
    },
    vdata: {
      type: Object,
      required: true
    }
  }
};
</script>
