/*
 *
 * @file password visibility
 *
 */

class PasswordVisibility {
  constructor(app) {
    this.init = this.init.bind(this);
    this.app = app;

    //#
    // DOM Elements
    // @type {string}
    //#
    this.el = {
      container: '[data-password-visibility]',
      toggle:    '[data-password-visibility-toggle]',
    };

    //#
    // DOM Element Modifiers
    // @type {string}
    //#
    this.el_modifiers = {
      toggle: {
        visible: 'password-visibility__toggle--visible'
      }
    };

    this.app.register_turbolinks_load_handler(this.init);
  }

  init() {
    if (!$(this.el.container).length) {
      return;
    }

    // Add DOM elements
    const $container = $(this.el.container);

    if (!$container.hasClass('password-visibility')) {
      $container.addClass('password-visibility')
      $container.find('input').addClass('password-visibility__input');
      $container.append('<div class="password-visibility__toggle" data-password-visibility-toggle data-cy="sign-up__toggle-password-visibility">hide</div>');
    }

    // Toggle visibility
    $(document).off('click', this.el.toggle);
    $(document).on('click', this.el.toggle, (e) => {
      const $target = $(e.currentTarget);
      const $toggle = $target.parent('div').find(this.el.toggle)
      const $input = $target.parent('div').find('input');

      $toggle.toggleClass(this.el_modifiers.toggle.visible);
      $input.prop('type', $input.prop('type') === 'text' ? 'password' : 'text');
      $toggle.text($toggle.text() === 'show' ? 'hide' : 'show');
      $input.focus();
    });
  }
}

export default PasswordVisibility;
