<template>
  <div class="media media--tertiary large-mt-10">
    <div
      class="media__content small-pl-15 small-pt-0 small-pr-15 small-pb-15 medium-pt-30 medium-pb-30"
    >
      <div class="row small-up-3 medium-up-5 summary-data">
        <div
          class="column position-relative small-mb-10 small-mt-10 large-mb-0 large-mt-0"
        >
          <div :class="currency_class" data-cy="investment-data__invested">
            {{ currency(Math.floor(data.amount_invested)) }}
          </div>
          <div class="media__text media__text--small">Invested</div>
          <div
            class="media__divider media__divider--small media__divider--right"
          />
        </div>
        <div
          class="column position-relative small-mb-10 small-mt-10 large-mb-0 large-mt-0"
        >
          <div :class="currency_class" data-cy="investment-data__distributed">
            {{ currency(Math.floor(data.amount_distributed)) }}
          </div>
          <div class="media__text media__text--small">Distributed</div>
          <div
            class="media__divider media__divider--small media__divider--right"
          />
        </div>
        <div
          class="column position-relative small-mb-10 small-mt-10 large-mb-0 large-mt-0"
        >
          <div
            :class="currency_class"
            data-cy="investment-data__return-of-capital"
          >
            {{ currency(Math.floor(data.return_of_capital)) }}
          </div>
          <div class="media__text media__text--small">Return of Capital</div>
          <div
            class="media__divider media__divider--small media__divider--right show-for-large"
          />
        </div>
        <div class="column position-relative">
          <div
            :class="currency_class"
            data-cy="investment-data__capital-balance"
          >
            {{ currency(Math.floor(data.capital_balance)) }}
          </div>
          <div class="media__text media__text--small">Capital Balance</div>
          <div
            class="media__divider media__divider--small media__divider--right"
          />
        </div>
        <div class="column position-relative">
          <div
            :class="currency_class"
            data-cy="investment-data__return-on-capital"
          >
            {{ currency(Math.floor(data.profit)) }}
          </div>
          <div class="media__text media__text--small">Return on Capital</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currency } from "shared/view-filters";

export default {
  name: "InvestmentData",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currency_class() {
      let big_text =
        this.data.amount_invested > 999999 ||
        this.data.amount_distributed > 999999 ||
        this.data.return_of_capital > 999999 ||
        this.data.capital_balance > 999999;

      return {
        media__text: true,
        "media__text--large": !big_text,
      };
    },
  },
  methods: {
    currency,
  },
};
</script>
